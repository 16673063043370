import {  IonContent, IonPage} from '@ionic/react';
import MlmHeader from '../components/MlmHeader';

const Privacy: React.FC = () => {


  return (
    <IonPage>
        <MlmHeader themecolor='black'/>
      <IonContent fullscreen className='main_content share_screen ion-padding'>
        <div className='container'>
        <h1>Privacy Policy</h1>
        

        
        
        
        <p>Last Updated: 05/01/2023</p>
        
        
        
        <p>Here at Matan’s Life Map, we want to be open and transparent about our practices around the information we collect and that you elect to share with us. We are committed to protecting the personal information that our users share with us and provide this Privacy Policy (“Privacy Policy”) to inform our users of our policies and practices regarding the collection, use, storage, and disclosure of information received or obtained when (i) users visit this website located at www.matanslifemap.com, app.matanslifemap.com and any other website that we own and/or operate (each a “Site” and collectively, the “Sites”); or (ii) users access and use the Service we provide. In this Privacy Policy, we, Matan’s Life Map, Inc., will be referred to as “MLM,” or “we,” “our,” or “us”, and you, the user accessing and using the Sites and Service, will be referred to as “you”, “your”, or “User.” Any capitalized but undefined term in this Privacy Policy shall have the meaning given to it in our <a href="https://matanslifemap.com/terms-of-service/">Terms of Use</a> (the “Terms”).&nbsp;</p>
        
        
        
        <p>YOUR CONSENT: Please read this Privacy Policy carefully and make sure you fully understand it. By accessing and using the Sites and Service you agree and consent to our collection, processing, transfer, and use of your information and the practices generally set forth in this Privacy Policy now and as amended by us. “Processing” means using information in any way, including, but not limited to, collecting, storing, deleting, using, combining and disclosing information, all of which activities will take place in the United States. You acknowledge and confirm that you are not required to provide us with your personal information and that you voluntarily provide such information to us. We are committed to protecting your information, but note that if you disagree with the terms herein, including our collection and use of your information, you may not access or use the Sites or the Service in any manner.</p>
        
        
        
        <p>If you reside outside the U.S., your personally identifiable information will be transferred to the U.S. and processed and stored there under U.S. privacy standards. By using the Sites and Service and providing information to us, you consent to such transfer to, and processing in, the U.S.. If you have any questions or comments about this Privacy Policy or our use of your personally identifiable information, please contact us at <a href="mailto:contact@matanslifemap.com">contact@matanslifemap.com</a>.&nbsp;</p>
        
        
        
        <p>You may also choose not to provide us with “optional” personal information (i.e., “not required” fields on forms), but please keep in mind that without it we may not be able to provide you with the full range of our Service or with the best user experience when using our Service. If you do not wish to provide us with your personal information or to have it processed by us, do not visit, use, or interact with the Sites or Service.</p>
        
        
        
        <p>MODIFICATIONS: This Privacy Policy may be updated from time to time for any reason; each version will apply to information collected while it was in place. We will notify you of any material changes to our Privacy Policy by posting the new Privacy Policy on our Sites. Your continued use of the Sites and Service after the changes have been implemented constitutes your acceptance of the changes and the then current Privacy Policy. You are advised to consult this Privacy Policy regularly for any changes.&nbsp;</p>
        
        
        
        <p></p>
        
        
        
        <ol>
        <li><strong id="what-information-we-collect">What Information We Collect</strong></li>
        </ol>
        
        
        
        <p>When we use the terms “<strong>personal data</strong>” or “<strong>personal information</strong>” in this Privacy Policy, we mean information that identifies, relates to, describes, is reasonably capable of being associated with, or could reasonably be linked, directly or indirectly, to an individual. It does not include aggregated or anonymized information that is maintained in a form that is not reasonably capable of being associated with or linked to an individual (“n<strong>on-personal information</strong>”).&nbsp;</p>
        
        
        
        <p>We may collect the following information when you use the Sites or Service, which includes both personal information and non-personal information.</p>
        
        
        
        <ul>
        <li><strong>Information from when you browse the Sites and Service</strong>. When you visit or use the Service, we may collect certain information from you, including, for example, your IP address, operating system, device-identifying information, how you interact with the Service, the specific web pages visited during your connection, and the domain name from which you accessed the Service. In addition, we may collect information about your browsing behavior, such as the date and time you visit the Service, the time zone and/or location from which you access the Service, the areas or pages of the Service that you visit, the amount of time you spend viewing the Service, the number of times you return to the Service and other similar data. We collect content you create on the Service as well as information about the content you create or provide. We may also use non-personal or aggregated information for statistical analysis, research, and other purposes.</li>
        
        
        
        <li><strong>Information You Provide when using the Service</strong>. We collect information from you when you submit it to us, including by: (1) registering an account on the Service; (2) using the Service, including when you provide responses to preliminary questions, and when you upload or input any information into the Service; (3) subscribing or placing an order; (4) signing up for communications; (5) contacting us; (6) leaving a review, or (7) participating in a promotion or survey. This information may include, for example, your first and last name, email address, mailing address, billing address, telephone number, date of birth, payment method, photographs you upload, and additional verification data, including government identification. You may also enter in certain passwords, usernames, account numbers, and other account information for websites, apps, products or services provided by third-party services providers. In order to display information to you through the Service, we may collect, on your behalf, your account information and other information from third-party service providers that you register under your account via the Service. We may request or receive other information such as feedback, questions, comments, suggestions, or ideas to provide you with other benefits or to improve upon the Service. In such instances, you will be given the opportunity to provide or decline that information.</li>
        </ul>
        
        
        
        <p>We may combine the information we collect from you with information we receive about you from other sources. For payments to us, we use a third-party card processor for all credit card transactions, and we do not store any credit card information on our Service. We may collect information from you when you write a review on our Service.</p>
        
        
        
        <p>In some jurisdictions, individuals may have the right to opt-in or withdraw consent for certain uses. If you reside in such jurisdictions, you may have additional rights which are detailed below, “Access, Correction and Deletion.”</p>
        
        
        
        <ul>
        <li><strong>Communications with our Customers and Users</strong>. Personal information contained in any forms and inquiries that you may submit to us, including support requests, interactions through social media channels and instant messaging apps, registrations to events that we host, organize or sponsor, and participation in our online and offline communities and activities; surveys, feedback and testimonials received; expressed, presumed or identified needs, preferences, attributes and insights relevant to our potential or existing engagement; and sensory information including phone call and video conference recordings (e.g., with our customer experience or product consultants), as well as written correspondences, screen recordings, screenshots, documentation and related information that may be automatically recorded, tracked, transcribed and analyzed, for purposes including analytics, quality control and improvements, training, and record-keeping purposes.</li>
        
        
        
        <li><strong>Information We May Collect From Other Sources. </strong>If you register or log into your account through a third-party service, we will have access to some of your third-party account information from that service, including identifiers, such as your name and other information in that account, and used for purposes such as improving your recommendations. We may also have access to third-party account information if you use the features of the Service to share a link.</li>
        </ul>
        
        
        
        <p>Notwithstanding anything else in this Privacy Policy, if you provide the Service with access to your Google account and Google data, the Service’s use of that data will be subject to these additional restrictions:</p>
        
        
        
        <p>With respect to personal information we obtain from Google OAuth API Scopes, used in our integration with certain Google Service (“Integrated Google Service”), our use of such personal information and data aggregated, anonymized, or derived therefrom, is limited to the following purposes, in adherence with the Limited Use requirements as detailed in the Google API Services User Data Policy:</p>
        
        
        
        <ul>
        <li>Facilitating, operating, supporting, providing and improving user-facing features that are prominent in the Integrated Google Service;</li>
        
        
        
        <li>Troubleshooting or security purposes (such as investigating a bug or abuse), subject to the Users’ prior consent, insofar access to personal information is required to resolve a support issue;</li>
        
        
        
        <li>Compliance with applicable law and regulations;</li>
        
        
        
        <li>For our internal operations, provided that the personal information (including derivations thereof) have been aggregated and anonymized;</li>
        
        
        
        <li>Transfer of the personal information to third parties: (1) in accordance with this Privacy Policy, solely to the extent necessary to provide or improve the Integrated Google Service; (2) to comply with applicable laws and regulations; and (3) in connection with any change in control, including by means of merger, acquisition or purchase of substantially all of our assets; or</li>
        
        
        
        <li>Otherwise in accordance with your affirmative agreement</li>
        </ul>
        
        
        
        <p>The Service will not use your Google data for serving advertisements.</p>
        
        
        
        <p>You represent and warrant that you are legally authorized to disclose such information to us and you are fully and solely responsible for providing all necessary information notices to affected data subjects and for obtaining the required consents under applicable law from these data subjects for the use, processing, sub-processing and storing of their information in accordance with this Privacy Policy and the Terms.</p>
        
        
        
        <ol>
        <li><strong id="how-we-use-your-information">How We Use Your Information</strong></li>
        </ol>
        
        
        
        <p>We use personal data as necessary for the performance of our Service; to comply with our legal and contractual obligations; and to support our legitimate interests in maintaining and improving our Service; providing customer services and technical support; and protecting and securing the Sites and the Service. We do not sell your information to any third party for any reason.&nbsp;</p>
        
        
        
        <p>We may use the information we collect from and about you for any of the following purposes:&nbsp;</p>
        
        
        
        <ul>
        <li>to enable the provision of our Sites and Service and for customization and development of the Sites and/or our Service;</li>
        
        
        
        <li>to respond to your inquiries, requests, comments, and suggestions;&nbsp;</li>
        
        
        
        <li>to send you confirmation emails, like when you subscribe to the Service or make a purchase;&nbsp;</li>
        
        
        
        <li>to review usage and operations of the Service;&nbsp;</li>
        
        
        
        <li>for statistical, analytical and research purposes of aggregated, anonymized, or pseudonymized non-personal data which may be used to provide and improve the Service and Sites and for business intelligence;</li>
        
        
        
        <li>to improve the Sites or our Service and enhance the user experience.</li>
        
        
        
        <li>to conduct internal operations, including for troubleshooting, data analysis, testing, research and statistical purposes;&nbsp;</li>
        
        
        
        <li>to provide support and technical assistance, including addressing questions users or merchants may have with the Service;&nbsp;</li>
        
        
        
        <li>to support and enhance our data security measures, including for the purposes of preventing and mitigating the risks of fraud, error or any illegal or prohibited activity;&nbsp;</li>
        
        
        
        <li>to invoice and process payments</li>
        
        
        
        <li>to publish your feedback and submissions to our Sites, public forums and blogs</li>
        
        
        
        <li>to comply with our legal and contractual obligations; maintain our compliance with applicable laws, regulations and standards; and in order to be able to protect our rights and legitimate interests;&nbsp;</li>
        
        
        
        <li>to contact you with updates, newsletters, announcements, conduct surveys, and other informational and promotional and marketing materials from us regarding our Service, by e-mail, SMS or phone; and</li>
        
        
        
        <li>for any other lawful purpose, or other purpose that you consent to in connection with provisioning our Service;&nbsp;</li>
        
        
        
        <li>to maintain our data-processing records and for general administrative purposes.</li>
        </ul>
        
        
        
        <p>If you do not wish to receive communications from us about special offers, promotions, and other marketing communications, you can opt-out of receiving these communications by following the instructions contained in the messages you receive or changing your account settings. Even if you opt-out of receiving these messages, we reserve the right to send you certain communications relating to the Service, and we may send you announcements and administrative messages. We do not offer you the opportunity to opt-out of receiving those communications. Additional information regarding how we communicate with you can be found in the Communication section below.&nbsp;</p>
        
        
        
        <ol>
        <li><strong id="how-we-disclose-information">How We Disclose Information</strong></li>
        </ol>
        
        
        
        <p>We may disclose information collected from and about you in the following situations:&nbsp;</p>
        
        
        
        <p>(1) to our related companies and service providers, to perform a business, professional or technical support function for us such as: hosting and server co-location services, communications and content delivery networks (CDNs), data and cyber security services, billing and payment processing services, fraud detection, investigation and prevention services, web and mobile analytics, email and communication distribution and monitoring services, remote access services, performance measurement, data optimization and marketing services, social and advertising networks, content, lead generating and data enrichment providers, email, voicemail, video conferencing solutions, support and customer relation management systems, third-party customer support providers, and our legal, compliance and financial advisors and auditors;&nbsp;</p>
        
        
        
        <p>(2) as necessary, subject to applicable laws, if we believe that there has been a violation of this Privacy Policy, the Terms, or our rights or the rights of any third party. Additionally, we may disclose information if we believe in good faith that our property or safety, any of our Users, or any member of the general public is at risk;</p>
        
        
        
        <p>(3) to facilitate community involvement through our public blogs, forums, communities, or social channels. Any information you submit on these forums, blogs and communities – including profile information associated with the User profile you use to post the information – may be read, collected, and used by others who access the Service. Due to the nature of such public forums, your posts and certain profile information may remain visible to all even after you terminate your User profile. To request removal of your information from publicly accessible parts of the Service operated by us, please contact us as provided below and note the part of the Service from which you would like your information to be removed. In some cases, we may not be able to remove your information, in which case we will let you know if we are unable to and why;&nbsp;</p>
        
        
        
        <p>(4) If you submit a public review or feedback, note that we may (at our discretion) store and present your review publicly, on our Service. If you wish to remove your public review, please contact us at <a href="mailto:contact@matanslifemap.com">contact@matanslifemap.com</a>. If you choose to send others an email or message inviting them to use the Service, we may use the contact information you provide us to automatically send such invitation email or message on your behalf. Your name and email address may be included in the invitation email or message;</p>
        
        
        
        <p>(5) to respond to legal process (such as a search warrant, subpoena or court order) and provide information to law enforcement agencies or in connection with an investigation on matters related to public safety, as permitted by law, or otherwise as required by law. Such disclosure or access may occur if we believe in good faith that: (a) we are legally compelled to do so; (b) disclosure is appropriate in connection with efforts to investigate, prevent, or take action regarding actual or suspected illegal activity, fraud, or other wrongdoing; or (c) such disclosure is required to protect the security or integrity of our products and Service; and&nbsp;</p>
        
        
        
        <p>(6) in the event that we or substantially all of our assets are acquired, your information including personal information may be one of the transferred assets. We may also disclose your information with your express consent. We may share aggregate, non-personally identifiable information about users with third parties. We may share your information in other ways if you have asked us to do so or have given consent.</p>
        
        
        
        <p>Please note that if you voluntarily submit any information, including personal information, for posting in a public area of the Service, such as a review or a blog post, the information becomes publicly available and can be collected and used by others, so you should use care before posting information about yourself online.</p>
        
        
        
        <ol>
        <li><strong id="communication">Communication</strong></li>
        </ol>
        
        
        
        <p>From time to time, we may need to contact you about the Service. We may do so through e-mail, phone, text message, and notifications. We may contact you with important information regarding our Service. For example, we may send you communications (through any of the means available to us) of changes or updates to our Service, billing issues, log-in attempts or password reset notices, etc. Other users may also send you notifications, messages and other updates regarding their or your use of the Service. You can control your communications and notifications settings by sending an e-mail to contact@matanslifemap.com. However, please note that you will not be able to opt-out of receiving certain Service communications which are integral to your use (like password resets or billing notices).</p>
        
        
        
        <p>We may also communicate with you about new features, additional offerings, events, promotional activities, and special opportunities or any other information we think you will find valuable. We may provide such communications through any of the contact means available to us (e.g., phone, mobile or email), through the Service, or through our marketing campaigns on any other sites or platforms. If you do not wish to receive such promotional communications, you may notify us at any time by sending an email to <a href="mailto:contact@matanslifemap.com">contact@matanslifemap.com</a>, or by following the “unsubscribe”, “stop”, “opt-out” or “change email preferences” instructions contained in the promotional communications you receive.</p>
        
        
        
        <ol>
        <li><strong>Other Important Information</strong></li>
        </ol>
        
        
        
        <p><strong id="children-under-the-age-of-16">Children under the age of sixteen</strong></p>
        
        
        
        <p>Our Service are not intended for anyone under the age of sixteen (16), nor do we knowingly collect personal information from anyone under the age of sixteen (16). No one under the age sixteen may provide any personal information to or on our Service. If you are under sixteen, do not use or provide any information on this website or through any of its features, do not register for our Service, do not make any purchases through our Service, do not use any of the interactive or public comment features of our Service, and do not provide any information about yourself to us, including your name, address, telephone number, email address, or any screen name or user name you may use.&nbsp;</p>
        
        
        
        <p>If we learn we have collected or received personal information from a child under sixteen, we will delete that information. If you believe we might have any information from or about a child under sixteen, please contact us using the information provided below.</p>
        
        
        
        <p id="cookies"><strong>Cookies</strong>.&nbsp;</p>
        
        
        
        <p>We may use cookies or “local storage” to, among other things: (1) allow you to use the Service without having to re-enter your username and password; (2) enhance or personalize your usage experience; (3) monitor usage; (4) manage the Service; and (5) improve the Service, including providing you with interest-based ads. If you choose, you can set your browser to reject cookies or you can manually delete individual cookies or all of the cookies on your computer by following your browser’s help file directions. However, if your browser is set to reject cookies or you manually delete cookies, certain functionality may be limited or restricted.</p>
        
        
        
        <p>We may also use web beacons and other applications or features to help us analyze user behavior and measure the effectiveness of the Service and our advertising. We may work with service providers that help us track, collect and analyze this information. We may combine the information we collect through cookies and web beacons with other information we have collected from you or information from other sources.</p>
        
        
        
        <p>Please see our <a href="https://matanslifemap.com/cookie-policy/">Cookie Policy.</a></p>
        
        
        
        <p id="do-not-track"><strong>Do Not Track Signals</strong></p>
        
        
        
        <p>Your browser settings may allow you to automatically transmit a “Do Not Track” signal to online services you visit. Note, however, there is no industry consensus as to what site and app operators should do with regard to these signals. Accordingly, unless and until the law is interpreted to require us to do so, we do not monitor or take action with respect to “Do Not Track” signals. For more information on “Do Not Track,” visit http://www.allaboutdnt.com.</p>
        
        
        
        <p id="access-correction-and-deletion"><strong>Access, Correction, &amp; Deletion</strong></p>
        
        
        
        <p>We respect your right to access and correct your personal information. You may access your personal information by signing into your account. From there, you can correct or modify your information. You may also exercise your rights, subject to applicable laws, to request certain information related to our data collection practices or that we delete or restrict access to your personal information. If you need assistance correcting or updating your personal information or would like to request that we delete your personal information, please contact us using the contact information detailed in the “Contact Us” section below.&nbsp;</p>
        
        
        
        <p id="retention-of-personal-information"><strong>Retention of Personal Information</strong></p>
        
        
        
        <p>Personal information that we collect, access or process will be retained only as long as necessary for the fulfilment of the purposes for which it was collected, unless otherwise provided in agreements between you and us or as required or authorized by law. Personal information that is no longer required to fulfill the identified purposes will be destroyed, erased or de-identified. However, note that unless otherwise explicitly specified herein and/or unless you instruct us otherwise, we may retain your personal information for as long as reasonably required to fulfill the purposes that the personal information was collected for, all as permitted under any applicable privacy laws. To determine the appropriate retention period for personal data, we consider the amount, nature, and sensitivity of such data, the potential risk of harm from unauthorized use or disclosure of such data, the purposes for which we process it, and the applicable legal requirements. Aggregated and/or anonymous information derived from your use of our Service may remain on our servers indefinitely unless restricted by applicable law.</p>
        
        
        
        <p id="users-from-outside-the-united-states"><strong>Users from Outside the United States</strong></p>
        
        
        
        <p>The Service are hosted in the United States. If you are using the Service from outside the United States, your information may be transferred to, stored and processed in the United States or other countries in accordance with this Privacy Policy. The data protection and other applicable laws of the United States or other countries may not be as comprehensive as those laws or regulations in your country or may otherwise differ from the data protection or consumer protection laws in your country. Your information may be available to government authorities under lawful orders and law applicable in such jurisdictions. By using the Service and/or providing personal information to us, you consent to the transfer, use, processing, and maintenance of your information on computers, services and systems located outside of your state, country or other government jurisdiction. If you are located outside the United States and choose to use the Service, you do so at your own risk.</p>
        
        
        
        <p>If you are located in the EEA, UK, or Switzerland, you may request information about how we transfer your personal data across borders by contacting us at contact@matanslifemap.com.</p>
        
        
        
        <p id="data-subject-rights"><strong>Data Subject Rights</strong></p>
        
        
        
        <p>To exercise any rights you may have under applicable privacy laws (such as the aforementioned right to access, the right to rectification and the right to delete) please contact us by sending an email to contact@matanslifemap.com with sufficient details, and we will respond within a reasonable time frame, but in any event no later than as required by applicable law.</p>
        
        
        
        <p id="security"><strong>Security</strong></p>
        
        
        
        <p>We use measures designed to maintain information we collect in a secure manner. We have taken certain physical, electronic, technical, and administrative steps and procedures to safeguard and secure the information we collect from Users. Even though we follow procedures designed to protect the information in our possession, no security is perfect, and we cannot promise, and you should not expect, that your information will be secure in all circumstances. We will make any legally required disclosures of any breach of the security, confidentiality, or integrity of your unencrypted electronically stored personal information to you via email or conspicuous posting on the Service or alternative method as may be required by law. There are inherent risks in the transmission of information over the internet or other methods of electronic storage, and we cannot guarantee confidentiality of your personal information or that unauthorized access or use of your personal information will never occur. We shall not be responsible or liable for unauthorized access, hacking, or other security intrusions or failure to store, or the theft, deletion, corruption, destruction, damage or loss of any data or information. Any transmission of personal information is at your own risk.</p>
        
        
        
        <p id="third-party-links"><strong>Third Party Links</strong></p>
        
        
        
        <p>The Sites and Service may contain links, and you may receive links from other users, to third-party websites, such as social media sites, which may have privacy policies and practices that differ from our own. The fact that we link to a website is not an endorsement, authorization or representation of our affiliation with that third party. We are not responsible for the quality, accuracy, timeliness, reliability or any other aspect of these products and services. In addition, a third-party will likely have privacy and data collection practices that are different from ours. If you make a purchase from a third-party on their site or on a site that we have posted a link to, the information obtained during your visit to that third-party’s online store or site, and the information that you give as part of the transaction, such as your name, e-mail address, street address, telephone number, and credit card number, may be collected by the third-party and us. We are not responsible for the activities and practices that take place on these sites or apps. For more information regarding a third-party, its online store, its privacy policies, and/or any additional terms and conditions that may apply, visit that third-party’s website and click on its information links or contact the third-party directly.</p>
        
        
        
        <p>You should also be aware that some content delivered through the Service originates with third parties. Such other content providers may place cookies on your computer, or otherwise gather information about you, including by asking you to provide information through the content player itself. Again, it is up to you to review the terms of use and privacy policy of the originators of such content.</p>
        
        
        
        <p id="third-party-service-providers"><strong>Third-Party Service Providers</strong></p>
        
        
        
        <p>In order to provide the Sites and Service, we may use third-party service providers which may collect, store and/or process the information detailed herein. Such third-party service providers may include without limitation the following service providers:</p>
        
        
        
        <ul>
        <li>Google: <a href="https://policies.google.com/privacy?hl=en-US" target="_blank" rel="noopener" aria-label="external link">https://policies.google.com/privacy?hl=en-US</a></li>
        
        
        
        <li>Google Analytics: <a href="https://www.google.com/analytics/terms/us.html" target="_blank" rel="noopener" aria-label="external link">https://www.google.com/analytics/terms/us.htm<strong>l</strong></a></li>
        
        
        
        <li>Google cloud: <a href="https://cloud.google.com/terms/cloud-privacy-notice" target="_blank" rel="noopener" aria-label="external link">https://cloud.google.com/terms/cloud-privacy-notice</a></li>
        
        
        
        <li>Hubspot: <a href="https://legal.hubspot.com/privacy-policy" target="_blank" rel="noopener" aria-label="external link">https://legal.hubspot.com/privacy-policy</a></li>
        
        
        
        <li>AWS: <a href="https://aws.amazon.com/service-terms/" target="_blank" rel="noopener" aria-label="external link">https://aws.amazon.com/service-terms/</a></li>
        
        
        
        <li>Kinsta: <a href="https://kinsta.com/legal/privacy-policy/" target="_blank" rel="noopener" aria-label="external link">https://kinsta.com/legal/privacy-policy/</a>&nbsp;</li>
        </ul>
        
        
        
        <p>Please read the third-party service providers’ terms of use and privacy policies to understand their privacy practices.</p>
        
        
        
        <p id="direct-marketing"><strong>Direct Marketing</strong></p>
        
        
        
        <p>You hereby agree that we may use the contact details you provided to us (including, without limitation, email address and phone number) for the purpose of providing you commercial and/or non-commercial materials related to our current and/or future products and services. You may withdraw your consent by sending a written notice the following email address: <a href="mailto:contact@matanslifemap.com">contact@matanslifemap.com</a>. Alternatively, you may follow the instructions for removing yourself from the mailing list, which are available in the messages you receive.</p>
        
        
        
        <p id="changes"><strong>Changes to Our Privacy Policy</strong></p>
        
        
        
        <p>We may amend this Privacy Policy at any time. If we make material changes to how we treat your information, we may notify you by email to the primary email address specified in your account or through a notice on the home pages <a href="http://www.matanslifemap.com" target="_blank" rel="noopener" aria-label="external link">www.matanslifemap.com</a> and www.app.matanslifemap.com, or as otherwise required by law. You should check the link to this Privacy Policy posted on our Sites for the current version.&nbsp;</p>
        
        
        
        <p id="contact-us"><strong>Contact Us</strong></p>
        
        
        
        <p>To ask questions or comment about this Privacy Policy and our privacy practices, contact us at: <a href="mailto:contact@matanslifemap.com">contact@matanslifemap.com</a></p>
        
        </div>
      </IonContent>
    </IonPage>
  );
};

export default Privacy;
