import React, { useEffect,useState} from 'react';
import { IonSpinner} from '@ionic/react';


type Props = {
    isloading: boolean, 
}

const Loader: React.FC<Props> = ({isloading: parentIsloading = true}): JSX.Element => {
  const [loading,setLoading] = useState(true);
  // setLoading(loader);
  useEffect(() => {
    // console.log('parentIsloading',parentIsloading);
    // console.log('loading',loading);
        
            setLoading(parentIsloading);
       
      }, [parentIsloading]);
    return(
        <div className='loader_wrapper'>
        {(loading) && (
            <div className="ion-text-center ">
              <IonSpinner name="crescent" />
            </div>
          )}
        </div>
    )
};
  
export default Loader;





