import React, { useState, useEffect, useRef } from 'react';
import { useHistory ,Redirect, Route} from "react-router-dom";
import {IonButton,IonBadge,IonText, IonContent,IonPage,  IonSlides,IonSlide, IonRouterOutlet,useIonViewWillEnter } from '@ionic/react';
import {  i18nConfig } from '../interfaces/Messages';
import { getQuestions,saveAnswers,getUserSummary,checkToken,getPaidStatus } from '../services/api';

import { detect } from 'detect-browser';

import MlmHeader from '../components/MlmHeader';
import Loader from '../components/Loader';
import WhiteWrapper from '../components/WhiteWrapper';
import Upgrade from './Upgrade';

import Login from '../components/Login';
// import 'swiper/swiper.min.css';
// import '@ionic/react/css/ionic-swiper.css';
interface Qwerty{
  code: string;
  message: string;
  statusCode: number;
  success: boolean;
}
interface Question{
  id: number;
  type: string;
  title:string;
  q: string;
  a: string;
  video: string;
  videowebm: string;
  bgcolor: string;
  bgimage: string;
  txtcolor: string;
}
interface Answer{
  id: string;
  title: string;
  answer:string;
}
const Page: React.FC = (e) => {
  const browser = detect();
 
  const [tr] = useState(JSON.parse(i18nConfig.trans));
  const [swiper, setSwiper] = useState<any>({});

  const [resetSlide, setResetSlide] = useState<Number>(0);
  const [currentSlide, setCurrentSlide] = useState<number>(Number(localStorage.getItem('currentSlide')) || 0);
  const [questions, setQuestions] = useState( null as Question[] | null  );
  const [userAnswers, setUserAnswers] = useState(null as Answer[] | null);
 
  const [hasAnswer, sethasAnswer] = useState(false);
  const [viewthumbnails, setThumbnails] = useState(false);
  const [paidUser, setpaid] = useState(false);
  const [authNeeded, setAuth] = useState(true);
  const [selectedButton, setSelectedButton] = useState('');
  
  const [qlength, setLength] = useState(0);
  const [visible, setVisible] = useState(false);
  const [theme, setTheme] = useState('white');
  const [audio, setAudio] = useState('https://app.matanslifemap.com/assets/sounds/Q1-31122022.mp3');
  
  // const [err, setErr] = useState( null as Qwerty[] | null  );
  const [err, setErr] = useState( '' );
  const [loading, setLoading] = useState(true);
  const history = useHistory();

const onFocus = (el:any) => {

  setTimeout(() => {
    el.target.scrollIntoView();
  }, 100);
}
const checkPaidStatus = async () => {
  const paidStatus:any = await getPaidStatus();
  // const paidStatus:any = false;
  console.log('paidStatus',paidStatus)
  if (paidStatus) {
    setpaid(paidStatus)
  }
};

const handleButtonClick = (buttonName:string) => {
  setSelectedButton(buttonName);
};
const handleAlternate = (event:any) => {
  event.preventDefault();
  console.log('event',event.target.id)
  const form = formRef.current;
  setSelectedButton(event.target.id);
  handleSubmit(form,event.target.id)
}

const formRef = useRef<HTMLFormElement>(null);

const handleSubmit = (data:any,btn:string) => {
  console.log(data);
  // data.preventDefault();


  // if (selectedButton === 'button1') {
  //   // Handle button 1 submit
  // } else if (selectedButton === 'button2') {
  //   // Handle button 2 submit
  // } else if (selectedButton === 'button3') {
  //   // Handle button 3 submit
  // }

  let answers:any = [];

  if (questions !== null ){
    for (let i = 0; i < data.length; i++) {
      
      answers.push({"id":questions[i].id,"title": questions[i].title,"answer":data[i].value});
    }
  }

  saveAnswers(answers).then(() => {

    if(btn ==='ShareBtn' || btn ==='finishShareBtn'){
     history.push('review');
    }
  })
   

 
}



  const fetchQuestions = async () => {
        
    setLoading(true);
    await checkPaidStatus();
    let ql:any = await getQuestions();
    // let ql:any = []

    let answers:any = await getUserSummary().then((response) => {
      console.log(response)
    if(response.length > 0){
      setUserAnswers(response);
      sethasAnswer(true);
      setTheme('black');
    }

  }).finally(() => {
      setLoading(false);
  });
    
   
    // if(answers && answers.length > 0){
    //   setUserAnswers(answers);
    //   sethasAnswer(true);
    //   setTheme('black');
      
    // }
    if (ql){
      
      setQuestions(ql);
      setLength(ql.length);
    }
    if (ql && ql.length > 0) {
      
      setAudio(ql[0].audio);
      
    }else{
      setErr('Questions not found.');
      
    }
   
    
    
  };


  const setCurrentActive = (index:Number)=>{
    swiper.slideTo(index);
    setResetSlide(index);
    var currentClosedSlide = document.getElementById('closedSlide_'+index);
    var question_slider_wrapper = document.getElementById('question_slider_wrapper');
    
    if (currentClosedSlide && question_slider_wrapper) {
      var l =currentClosedSlide.offsetLeft; 
      var t = currentClosedSlide.offsetTop;

      // currentClosedSlide.style.top = t+'px';
      currentClosedSlide.style.position = 'fixed';

      const newspaperSpinning = [
        { top: t+'px', left:l+'px' },
        { top:'0px',left: '0px' }
      ];

      const newspaperTiming = {
        duration: 500,
        iterations: 1,
      }
      
      currentClosedSlide.animate(newspaperSpinning, newspaperTiming);

      currentClosedSlide.classList.add("closedSlide-active");
      question_slider_wrapper.classList.add("question_slider_wrapper-active");
      if (questions) {
        
        playVideo(swiper.activeIndex,questions[swiper.activeIndex].video,questions[swiper.activeIndex].videowebm);
        setTheme(questions[swiper.activeIndex].txtcolor);
      }
      
    }
  }
  const removeCurrentActive = (index:Number)=>{
    console.log('index',index)
    var currentClosedSlide = document.getElementById('closedSlide_'+index);
    var question_slider_wrapper = document.getElementById('question_slider_wrapper');
    var current = document.getElementById('slide_'+index);
    
    if (currentClosedSlide && question_slider_wrapper) {

      currentClosedSlide.style.position = 'relative';


      currentClosedSlide.classList.remove("closedSlide-active");
      question_slider_wrapper.classList.remove("question_slider_wrapper-active");
      current?.classList.remove("slide-active");
      
    }
  }
  const init = async function(this: any) {
    setSwiper(await this.getSwiper());
    
};

const onSlideChange = () =>{
  
  
  if (swiper.activeIndex !== undefined) {
    localStorage.setItem('currentSlide',String(swiper.activeIndex));
    setCurrentSlide(swiper.activeIndex);
    
  }
  
  var previousSlide = document.getElementById('slide_'+swiper.previousIndex);
  if (previousSlide !== null) {
    console.log('previousSlide',swiper.previousIndex)
    
    previousSlide.classList.remove("slide-active");
    previousSlide.classList.remove("slide-active-view");
  }
  if(questions){
    if (swiper.activeIndex !== undefined){

      let q:any = questions[swiper.activeIndex];
      playVideo(swiper.activeIndex,q.video,q.videowebm);
      setTheme(q.txtcolor);
      setAudio(q.audio);
    }
  }

}
const playVideo = (index:any,mp4?:any,webm?:any) =>{

  let active = currentSlide;
  if (swiper.activeIndex !== undefined) {
     active = swiper.activeIndex;
  }
  var video = document.getElementById('video_'+ active) as HTMLVideoElement;
  var sources = video.getElementsByTagName('source');
  console.log(sources)
  if(browser && browser.name !=="safari"){
  sources[0].src = webm;
  sources[1].src = mp4;
  }else{

  sources[0].src = mp4;
  }

  video.playbackRate = 1.0;
  video.play();
 
}
const videoCallback = () =>{
  
  var slide = document.getElementById('slide_'+currentSlide);
  if (slide !== null) {
      slide.classList.add("slide-active");
      
  }
  var textarea:any = document.getElementById('answer--'+currentSlide);
  textarea.style.cssText = 'height:auto;';
  textarea.style.cssText = 'height:' + textarea.scrollHeight + 'px';
  setVisible(true);
  return;

  
}

const handleSlidesControlNext = () =>{
  swiper.slideNext();
  
}
const handleSlidesControlPrev = () =>{
  swiper.slidePrev();
}
const slideOpts = {
  initialSlide: currentSlide,
  pagination:false,
  speed: 400,
};
const handleFormChange = (event:any) => {
  var el = event.target;
  setTimeout(function(){
 
      el.style.cssText = 'height:auto;';
      el.style.cssText = 'height:' + el.scrollHeight + 'px';
      el.scrollIntoView();
    },0);
  
}



const addBadge = (badge:string) => {
   
    var textarea:any = document.getElementById('answer--'+currentSlide);
    if(textarea.value.length<1){
      textarea.value= badge + ': ';
    }else{
      textarea.value= textarea.value + '\r\n'+badge + ': ';
    }
    textarea.focus();
    
};

const skipAnimation =()=>{
  console.log('skipAnimation',currentSlide)
  var video = document.getElementById('video_'+ currentSlide) as HTMLVideoElement;
  console.log(video)
  video.playbackRate = 6.0;
}
useEffect(() => {

  
  
  let tokenIsValid = checkToken();
  
  if(tokenIsValid){
    setAuth(false)
    
    fetchQuestions(); 
  }else{
    history.push('/');
  }
  
  
}, [authNeeded])

useIonViewWillEnter(() => {
  var s = document.getElementsByClassName('closedSlide-active') ;
  var aNode = s[0]as HTMLElement;

    if(aNode && aNode.dataset.index){
      removeCurrentActive(parseInt(aNode.dataset.index))
    }
});
useEffect(() => {

     if (questions && questions.length > 0 && !hasAnswer) {
        let q: any = questions[swiper.activeIndex];
        playVideo(swiper.activeIndex,q.video,q.videowebm);
        setCurrentSlide(swiper.activeIndex);
        setCurrentActive(swiper.activeIndex)
      }
      
      if (browser) {
        console.log(browser.name);
        console.log(browser.version);
        console.log(browser.os);
      }
  
}, [questions])
useEffect(() => {
  var question_slider_wrapper = document.getElementById('question_slider_wrapper');
  question_slider_wrapper?.classList.remove("question_slider_wrapper-active");
  var currentS = document.getElementById('closedSlide_'+resetSlide);
  if(currentS){
    
    currentS.classList.remove("closedSlide-active");
    currentS.style.position = 'relative';
  }
  setTheme('black');
  

}, [e])

  return (

    <IonPage>
     {(!loading && !paidUser && !authNeeded)&&(<Upgrade/>)}
     {(authNeeded && !loading) && (
      <>
      <div className="video_wrapper q_video_wrapper">
      <video  muted id='home_video' autoPlay loop playsInline>
       
        {(browser && browser.name !=="safari") &&(
          <source id="source_webm_home" src='/assets/videos/SPLASH_ANIM_LOOP1_V2.webm' type="video/webm"/>
        )}
       <source id="source_home" src='/assets/videos/SPLASH_ANIM_LOOP1_V2.mp4' type="video/mp4"/>
       </video>
   </div>
   <div className="welcome-wrappe">
     <div className="welcome_content">
          <Login stateChanger={setAuth}/>
        </div>
        </div>
        </>
          )}
      <MlmHeader themecolor={theme} from="header" audiofile={audio} menu={authNeeded?false:true}/>
      
    
      <IonContent fullscreen   className="main_content questions_wrapper" >
      

          {(err) && (
          <WhiteWrapper text={err} cls="abs_position"/>
            
          )}



          <Loader isloading={loading}/>


          {(hasAnswer) &&(
            <div className={` hasAnswer_${hasAnswer}`} id="question_slider_wrapper">
              
              <h1>My Life Map </h1>
              <div className="question_slider_wrapper" >
                <div className="question_slider_content">
                {questions && questions.map( (q:any,index:any)=>
                (
                    
                    <div className="q_slide_wrapper" key={index} data-index={index}  id={`closedSlide_${index}`} style={{color:q.txtcolor}} onClick={() => {
                      setCurrentSlide(index);
                      setCurrentActive(index)}}>
                        <div className="q_slide_content" >
                            <div className="video_wrapper" style={{backgroundImage: `url(${q.bgimage})` }} >
                            
                              <div className="bgOverlay" style={{backgroundColor: q.bgcolor }}></div>
                            </div>
                          
                            <div className="q_slide" >
                              <div className="container">
                                
                                <h1><small>{(index < 9)?0:''}{index + 1}</small> {q.title}</h1>
                                
                              </div>
                            </div>
                        </div>
                      </div>
                )
                )}
                </div>
              </div>
              <IonButton  shape="round" size="large" fill="clear" type="submit" routerLink="onboard" routerDirection="none" className={`instruction_button share_button header_${theme}`} >
              Instructions
        </IonButton>
            </div>
                
        
          )}
         
            <form id="formh"  ref={formRef}>
        <IonSlides pager={true} onIonSlidesDidLoad={init} options={slideOpts} onIonSlideDidChange={() => onSlideChange()} onIonSlideWillChange={() => setVisible(false)} className="swiper-no-swiping">
      {questions && questions.map( (q:any,index:any)=>
       (
           
           <IonSlide  className="ion_slide_wrapper " key={index} style={{color:q.txtcolor,}}>
           <div className="slide_wrapper" id={`slide_${index}`}>
           <div className="video_wrapper">
           <video playsInline  muted id={`video_${index}`} onEnded={() => videoCallback()}>
           {(browser && browser.name !=="safari") &&(
         <source id={`source_webm_${index}`} src={`/assets/videos/Q${index + 1}_ANIM_V2.webm`} type="video/webm"/>
        )}
          
            {/* <source id={`source_${index}`} src={q.video} type="video/mp4"/> */}
            <source id={`source_${index}`} src={`/assets/videos/Q${index + 1}_ANIM_V2.mp4`} type="video/mp4"/>
            </video>
            <div className="bgOverlay" style={{backgroundColor: q.bgcolor }}></div>
           </div>
           
            <div className="slide ion-padding" >
              <div className="container">
                <div className="slideintro"onClick={skipAnimation}>

              <h1 className='slideTitle'><small>{(index < 9)?0:''}{index + 1}</small> {q.title}</h1>
                </div>
              <div className="q_content">
              <h1><small>{(index < 9)?0:''}{index + 1}</small> {q.title}</h1>
              <IonText className='questionTxt'>{q.q}</IonText>
              <div className="badges_wrapper">
                
              {q.badges && q.badges.map( (badge:any,index:any)=>
                (
                  <IonBadge key={index} className='ion-badge-black' slot="start" onClick={() => addBadge(badge)} >{badge}</IonBadge>
                  
                  )
                  )}
                  </div>
                 
                  <textarea key={index} className='textarea-black' id={`answer--${index}`} name={`answer--${index}`} onChange={(event:any) => handleFormChange(event)} onFocus={onFocus}  defaultValue={((userAnswers) && (userAnswers.length > 0) ) ? userAnswers[index].answer :''}></textarea>
                  
                
                        {/* <p><textarea key={index} id={`answer--${index}`} onChange={handleInput}>{q.a}</textarea></p> */}
                      
                        {/* <p>{q.txtcolor}</p>
                        <p>{q.bgcolor}</p>
                      <p>{q.bgimage}</p> */}
                        </div>
                        </div></div>
                      </div>
                    </IonSlide>
                )
                )}
          
        </IonSlides>

        <div className={`bottom-buttons buttons_${visible}`}>
         
          
        {(currentSlide === 0) ? '': <IonButton className='white-button ' shape="round" size="large" id="prevBtn" onClick={(e) => {handleAlternate(e);handleSlidesControlPrev()}}>
        &#60;{(currentSlide < 10)?0:''}{currentSlide }
        </IonButton> }
        
        <IonButton shape="round" size="large" fill="clear"  className={`share_button header_${theme}`} id={(currentSlide+1 === qlength) ? 'ShareBtn': 'finishShareBtn'} onClick={(e) =>{handleAlternate(e)}}>
         Finish & Share
        </IonButton>
        {(currentSlide+1 === qlength) ? '': <IonButton className='white-button ' shape="round" size="large"  id="nextBtn" onClick={(e) => {handleAlternate(e);handleSlidesControlNext()}} >{(currentSlide+1 < 9)?0:''}{currentSlide + 2}&#62;
        </IonButton> }
        
        </div>
        </form>
          
          

      </IonContent>
    </IonPage>

  );
};

export default Page;
