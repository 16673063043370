import React, { useState, useEffect } from 'react';
import { IonList,IonItem,IonIcon, IonContent,  IonPage, IonButton } from '@ionic/react';
import { sharedWithMeUsers,sharedWithMeAnswers,checkToken ,getPaidStatus, getUserSummary} from '../services/api';
import { useHistory,useLocation } from "react-router-dom";
import MlmHeader from '../components/MlmHeader';
import Loader from '../components/Loader';
import WhiteWrapper from '../components/WhiteWrapper';

interface personData{
  id: number;
  title:string;
  answer: string;
}
interface shared{
  id: string;
  fullName: string;
  nickName: string;
  firstName: string;
  lastName: string;
}
interface Answer{
  id: string;
  title: string;
  answer:string;
}
const Page: React.FC = () => {

  const [sharedUsers, setshared] = useState( null as shared[] | null  );
  const [personData, setPersonData] = useState( null as personData[] | null  );
  const [person, setperson] = useState( '' );
  const [loading, setLoading] = useState(true);
  const [noOne, setnoOne] = useState(false);
  const [paidUser, setpaid] = useState(false);
  const [userAnswers, setUserAnswers] = useState(null as Answer[] | null);

  const search = useLocation().search;  
  const id = new URLSearchParams(search).get('id');
  const firstName = new URLSearchParams(search).get('firstName');

  const history = useHistory();
  const fetchAnswer =async()=>{
    setLoading(true);
    let answers:any = await getUserSummary();
    // let answers:any = [];
    if(answers && answers.length > 0){
      setUserAnswers(answers);
      
    }
    setLoading(false);
  }
  const fetchSharedWithMeUsers = async () => {
    setLoading(true);
    const sharedUsers:any = await sharedWithMeUsers();
console.log(sharedUsers)
    setshared(sharedUsers);

    if(sharedUsers.length === 0){
      setnoOne(true);
    }
    setLoading(false);
    
  };

  const fetchPersonData = async (id:string) => {
   
    setLoading(true);

    const personData:any = await sharedWithMeAnswers(id);
    if(personData){
      setLoading(false);

      setPersonData(personData);
    }
    // console.log('loading',loading);
    // console.log('personData',personData);
    
  };
  const checkPaidStatus = async () => {
    setLoading(true);
    const paidStatus:any = await getPaidStatus();
    console.log('paidStatus',paidStatus) 

    if (paidStatus) {

      setpaid(paidStatus)
    }
   setLoading(false);
  };
  
  useEffect(() => {
    let tokenIsValid = checkToken();
      if(!tokenIsValid){
        console.log(tokenIsValid);
        history.push('/');
      }
      console.log('here');
        fetchSharedWithMeUsers();
        checkPaidStatus();
        fetchAnswer();
      
      if(id){
        fetchPersonData(id);
      }
      if(firstName){
        setperson(firstName);
      }
  }, [])
    
    var acc = document.getElementsByClassName("accordion");
    var i;

    const openAccordion = (e:any) =>{
        console.log(e);
        var el = e.target;
        e.target.classList.toggle("active");
        var panel = el.nextElementSibling;
        if (panel.style.maxHeight) {
            panel.style.maxHeight = null;
        } else {
            panel.style.maxHeight = panel.scrollHeight + "px";
        }
    }
    const goBack = () => {
      window.history.back();
    }

    
 

  return (
    <IonPage>
             <MlmHeader themecolor='black' position="relative"/>


      <IonContent fullscreen className='main_content share_screen'>
       <div className="container flex-container">
        
       <Loader isloading={loading}/>
       {(person)  && (
              <h1 className='share_title'> 
              <small>
              {person}'s
              </small>Life-Map</h1>
              )}
             
            {(noOne) &&  (
              <><h1 className='share_title'> 
              Shared with me</h1>
              
              <WhiteWrapper text={`No one has shared their Life Map with you, yet.\n\nLet’s change that!\n\nWho should you encourage to create their Life Map? Send them a link to matanslifemap.com. Or, if you share your Life Map with them, they can register to create their own Life Map right from their app.\n\nIt will provide you and them with peace of mind.`} />
              <div className="bottom-buttons review-bottom-buttons buttons_true">
              <IonButton onClick={goBack} className='black_button' shape="round" size="large" >Back </IonButton>
            {(!paidUser)&&(
            <IonButton slot="end" shape="round" size="large" className=' white-button' routerLink="upgrade">   Create your own </IonButton>        
            )}
             
              
              </div>
              </>
              )}

             
              {(!person && !noOne) && (
                <>
                <h1 className='share_title'> 
                Shared with me</h1>
            <IonList lines="full" className="white_list">
            {!person && sharedUsers && sharedUsers.map( (p:any,key:any)=>
              (
              <IonItem className="" key={key} onClick={() => {
                setperson(p.firstName);
                fetchPersonData(p.id)}}>
              
              
              <h1>{p.firstName}'s </h1>
              <IonIcon slot="end" icon="./assets/img/icon/eye.svg" ></IonIcon>
              
              </IonItem>
                )
                )}
            </IonList>
            {!userAnswers && !loading && (
            <div className="bottom-buttons review-bottom-buttons buttons_true">

            <IonButton slot="end" shape="round" size="large" className=' white-button' routerLink="questions">   Create your own </IonButton>        
            </div>
            )}
            </>
            )}
            
        {(person) && (
       
       <div className="accordion_wrapper"> 
       <div>
       {personData && personData.map( (q:any,key:any)=>(
              <div className="cl-accordion" key={key}>
              
          <button className="accordion" onClick={openAccordion}>{key+1} -  {q.title}</button>
          <div className="panel">{q.answer}</div>
              </div>
       )
       )}
       </div>
       </div>
       
         )}
           {(person)  && (
              <div className="bottom-buttons review-bottom-buttons buttons_true">
              <IonButton onClick={() => {
                              setperson('');
                              setPersonData(null)}} className='white-button' shape="round" size="large" >Back </IonButton>
              
              {(!paidUser)&&(
            <IonButton slot="end" shape="round" size="large" className=' white-button' routerLink="upgrade">   Create your own </IonButton>        
            )}
              </div>
              )}
        
      </div>
      
      
       
      </IonContent>
    </IonPage>
    
  );
};

export default Page;
