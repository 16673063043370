import {  IonContent, IonPage} from '@ionic/react';
import MlmHeader from '../components/MlmHeader';


const Terms: React.FC = () => {


  return (
    <IonPage>
        <MlmHeader themecolor='black'/>
      <IonContent fullscreen className='main_content share_screen ion-padding'>
        <div className='container'>
 

				<h1>Terms of Service</h1>




<p>Last Updated: 05/01/2023</p>



<p>Welcome to Matan’s Life Map! Thank you for using our Service.</p>



<p>Just about everyone has a story about losing a loved one and the struggle to find where some of the important elements of their lives are. Bank accounts, important documents, even the car’s spare keys. At Matan’s Life Map we created a simple solution: a tool with 12 questions that takes about 45 minutes to complete. It captures the key information your loved ones will need in case something happens to you.&nbsp;</p>



<p>To do so, we have set forth the terms below by which we will provide our Service to you. They are important and you may only access and use our Sites and Service in accordance with these Terms, so please take the time to read through them, understand them, and accept them before using our Service.&nbsp;</p>



<p>We are Matan’s Life Map, Inc. (“MLM,” “we,” “Us,” or “Our”) and provide our Sites and Service to your (“User,” “you,” or “your”) under certain terms and conditions. Our Terms of Use (the “Terms”) as amended from time to time, along with any other terms and policies (such as our Privacy Policy) referenced herein constitute a legally binding agreement and govern your connection to, access and use of www.matanslifemap.com, app.matanslifemap.com, and any related website and subdomain (each a “Site” and collectively the “Sites”) owned or operated by Matan’s Life Map, Inc..&nbsp;</p>



<p>PLEASE READ THESE TERMS CAREFULLY AS THEY CONTAIN IMPORTANT INFORMATION REGARDING YOUR LEGAL RIGHTS, REMEDIES AND OBLIGATIONS. THESE INCLUDE VARIOUS LIMITATIONS AND EXCLUSIONS AND A DISPUTE RESOLUTION CLAUSE THAT GOVERNS HOW DISPUTES WILL BE RESOLVED. YOU ACKNOWLEDGE THAT THESE TERMS ARE BINDING, AND YOU AFFIRM AND SIGNIFY YOUR CONSENT TO THESE TERMS AND BE BOUND BY THEM, BY ENTERING, CONNECTING TO, ACCESSING OR USING THE SITE AND BY ENTERING, CONNECTING TO, ACCESSING, USING, INSTALLING AND/OR DOWNLOADING THE LOCAL INTERFACE ON YOUR DEVICE OR TABLET THAT YOU OWN OR CONTROL.</p>



<p>IF YOU DO NOT AGREE TO COMPLY WITH AND BE BOUND BY THESE TERMS OR DO NOT HAVE LEGAL AUTHORITY TO BIND YOURSELF OR ANY OTHER ENTITY (AS APPLICABLE), YOU MAY NOT ACCESS, CONNECT, DOWNLOAD OR USE THE SERVICE OR THE SITES.</p>



<ol>
<li><strong>Matan’s Life Map Service.</strong></li>
</ol>



<p>We provide an online service inclusive of any and all functionality, application programming interface, interactive features, updates, and tools that allows individuals to create and share what we call Life Maps (the “Service”). Our Service allows users to upload, store, document, and share aspects of their personal lives so that family and friends may access that information at times of need. The Service can be accessed online or through an interface that you download to your local machine. Anyone who is otherwise eligible to use the Service in accordance with these Terms may browse the public-facing pages of the Service, but to use most features of the Service, you must create an Account (as defined below). The features and functionality available to you will be determined by the level which you select and pay for. We will endeavor to provide general e-mail support through the “Contact Us” section of the Site, but we make no representation or warranty that we will be able to respond to your query or resolve your issue in a timely manner or at all.&nbsp;&nbsp;</p>



<p>We may add, modify, or discontinue any feature, functionality, or any other tool, within the Service and/or Sites, at our own discretion and without further notice, however, if we make any material adverse change in the core functionality of the Service, then we will notify you by posting an announcement on the Sites and/or via the Service or by sending you an email. However, MLM has no obligation to provide upgrades or new releases of the Sites and/or the Service under these Terms.</p>



<ol>
<li><strong>Account Registration and Use</strong></li>
</ol>



<p>Subject to these Terms, and your compliance thereof, and specifically in strict compliance with our Use Restrictions below, we grant you a limited, worldwide, non-exclusive, non-transferable revocable right to access and use the Service and Sites solely for your own personal, non-commercial purposes.</p>



<p>To access and use most of the features and functionality of the Service, you will need to create an account with the Service (an “Account”). The Service is only meant for individuals at least sixteen (16) years of age and older or the minimum age at which you can legally agree be bound to the terms of an agreement and those terms are enforceable against you in the jurisdiction in which you reside. If you are not at least this age you may not register for or use the Service. By registering an Account, you represent and warrant that: (a) at least sixteen (16) years of age and older or the minimum age at which you can legally agree be bound to the terms of an agreement and those terms are enforceable against you in the jurisdiction in which you reside; (b) you have not previously been suspended or removed from our Sites and/or Service; and (c) your registration and your use of the Sites and Service complies with all applicable laws and regulations.</p>



<p>When creating an Account, you: (i) agree to provide us with accurate, complete, and current registration information about yourself; (ii) acknowledge that it is your responsibility to ensure that your password remains confidential and secure; (iii) agree that you are fully responsible for all activities that occur under your Account and password, including any integration or any other use of third party products or services (and associated disclosure of data) in connection with the Service; and (iv) undertake to immediately notify us in writing if you become aware of any unauthorized access or use of your Account and/or any breach of these Terms. You will be solely responsible and liable for any losses, damages, liability and expenses incurred by us or a third party, due to any unauthorized usage of your Account by either you or any other user or third party acting on your behalf or otherwise.&nbsp;</p>



<p>Please note that each Account is connected to a single e-mail address and that cannot be changed after Account registration is complete. This means that you will not be able to change your e-mail address connected to your Account and will need to register a new Account with the new e-mail address and pay additional fees. Any new Account will not be linked or connected to the previous Account or any information in that Account. If you lose access to the e-mail account that is connected to your Account then you may not be able to access your Account.&nbsp;</p>



<p>You understand and agree that we may require you to provide information that may be used to confirm your identity and help ensure the security of your Account. In the event that you lose access to your Account or otherwise request information about your Account, we reserve the right to request from you any verification we deem necessary before restoring access to or providing information about such Account. Inaccurate, incomplete, or obsolete information may result in the immediate termination of your Account.&nbsp;</p>



<p>You may cancel your Account by using the applicable functionality in the Service or by contacting us in the manner listed below. Cancelling your Account may cause the loss of certain information you provided to us. You agree that MLM is not liable for any loss of data due to the termination or cancellation of your Account. We have the right to disable any Account access credentials, whether chosen by you or provided by us, at any time in our sole discretion for any or no reason, including if, in our opinion, you have violated any provision of these Terms.</p>



<p>As part of the Service, you may submit, input, and upload information about yourself and others that may be shared with other individuals that you identify from time to time. You are solely responsible for all forms of content, including the content, that you submit, input or upload on or to the Service, or otherwise transmit to or share with other users (collectively, the “Content” and as further defined herein). You agree and acknowledge that you have all right, license, permission, and authority for any content you submit, input, or upload to the Sites or the Service and that any content you submit adheres to these Terms and any other agreement incorporated herein. MLM is not responsible for the content you or any other user submits. We reserve the right to, but are not obligated to, (i) review or screen any Content submitted to the Sites or otherwise submitted to the Sites or the Service; (ii) edit any Content submitted to the Sites or the Service; and/or (iii) remove or take down any Content from the Sites or Service for any reason, at any time, without prior notice, at our sole discretion. MLM will have no liability or responsibility to users of the Sites or Service or any other person or entity for performance or nonperformance of such activities.</p>



<ol>
<li><strong>Your Information &amp; Content.</strong></li>
</ol>



<p>As part of your use of the Service, you may be asked for certain information. The information that you upload, submit, post, publish, transmit or otherwise make available to or through the Sites and/or Service including any images, text, files, reports and other data shall be “Content.” Any Content that you enter into a Life Map shall be considered “Life Map Content,” and any Content that you enter into any other portion of the Sites and Service related to your use and our operation of the Sites and Service, such as your Account registration, public comments, chat messages, support communications shall be “User Content”. Depending on how you use the Sites and Service some of the Content (such as Life Map Content) that you provide to us will be encrypted in a manner that prevents access to anyone who does not have the username and password, and in those instances, the encrypted nature of that Content will inhibit our ability to access and view that Content.</p>



<p>When you upload, post, publish or make available any User Content on the Service, you grant to us and our affiliates and service providers, and each of their and our respective licensees, successors, and assigns an irrevocable, perpetual, non-exclusive, royalty-free, transferable, assignable, fully sub-licensable and worldwide license to use, reproduce, distribute, transmit, create derivative works of, display, copy and perform that User Content, along with the name that you submit in connection with such User Content, in connection with the Sites and/or the Service, whether through the internet, mobile devices or otherwise, in any media formats and through any media channels known today and developed in the future. You hereby agree and understand that you will not be entitled to prevent any transfer of User Content to any third party. MLM shall not bear any liability for any use by any third party of the User Content. In addition, you hereby explicitly waive any moral right you may have in and to the User Content and forever waive and agree not to claim or assert any entitlement to any and all moral rights in any of the User Content.</p>



<p>When you upload, post, publish or make available any Life Map Content on the Service, you grant to us and our successors, and assigns a revocable, non-exclusive, royalty-free, transferable, assignable, fully sub-licensable and worldwide license to use, reproduce, distribute, transmit, create derivative works of, display, copy and perform that Life Map Content, solely in connection with providing the Sites and/or the Service to you and with those you share your Life Map, whether through the internet, mobile devices or otherwise, in any media formats and through any media channels known today and developed in the future. It is your responsibility to obtain any and all forms of consent required under any applicable laws regarding the posting of any information including personal information of others that is part of the Content, and to adhere to any applicable laws regarding such information. You represent and warrant that: (i) you are the rightful owner or have obtained all rights, licenses, consents, permissions, power and/or authority, necessary to grant the rights granted herein, for any Content that you provide, submit, post or display on or through the Service; (ii) the Content is in compliance with, and subject to, our Use Restrictions; and (iii) the Content you submit or that is submitted on your behalf does not and shall not (a) infringe or violate any patents, copyrights, trademarks or other intellectual property, proprietary or privacy, data protection or publicity rights of any third party; (b) violate any applicable local, state, federal and international laws, regulations and conventions, including those related to data privacy and data transfer and exportation; (c) violate any third party’s policies and terms governing the Content.</p>



<p>You agree that you will not post or upload any Content containing content that is unlawful for you to possess, post or upload in the country in which you reside, or that would be unlawful for MLM to use or possess in connection with the Sites and/or the Service (including but not limited to any content that is defamatory, libelous, pornographic, indecent, harassing, threatening, discriminatory, abusive or fraudulent). We assume no responsibility or liability for Content, and you shall be solely responsible for Content and the consequences of using, disclosing, storing, or transmitting it.</p>



<p>.&nbsp;</p>



<p>You are solely responsible for creating and maintaining backup copies of any Content you upload, post or make available through the sites and service during any applicable term at your sole cost and expense. You maintain responsibility and liability for any losses or damages you incur for failure to maintain backup copies of your Content. MLM is not liable to you for any damages or losses that result from the loss or breach of your Content in connection with your use of the sites or service.</p>



<p>The Service is not designed or intended for certain types of protected information. You agree that you shall not submit to the Sites or Service any data that is protected under a special legislation and requires any unique treatment, including, without limitations, (i) categories of data enumerated in European Union Regulation 2016/679, Article 9(1) or any similar legislation or regulation in other jurisdiction; (ii) any protected health information subject to the Health Insurance Portability and Accountability Act (“HIPAA”), as amended and supplemented, or any similar legislation in other jurisdictions, unless you and MLM separately enter into a HIPAA Business Associate Agreement; (iii) information that is protected by the Federal Credit Reporting Act; and (iv) federal tax information or other financial information except to the limited extent required to pay for the Service. MLM takes no responsibility and assumes no liability for any Content uploaded, posted, published or made available by you or any third party in the Service, or for any loss or damage thereto, nor are we liable for any mistakes, defamation, libel, falsehoods, obscenities or pornography you or any other third party may encounter via the Sites and/or the Service.&nbsp;</p>



<ol>
<li><strong>Fees &amp; Taxes.</strong></li>
</ol>



<p>You may be able to access and use the Sites as well as view Life Maps shared with you free of charge. To create a Life Map you will need to register an Account and pay the stated fees. You agree to the fees, expenses, and charges specified on the payment form (collectively, “Subscription Fees”) in accordance with these Terms. You hereby authorize us, either directly or through our payment processing service or our affiliates, to charge such Subscription Fees via your selected payment method, at the time of check out or upon each due date if the Subscription Fees are billed on an annual basis. Unless expressly set forth herein, the Subscription Fees are non-cancelable and non-refundable. We reserve the right to charge fees for certain features or Service available via our Sites in the future and to modify the fees and prices for our Service at any time, at our discretion. Such pricing modifications will not affect your already paid subscription. We will notify you in advance, by placing a notice on our Site, before any charges or fees relating to such use come into effect. In the event of failure to collect the Subscription Fees owed by you, we may, at our sole discretion (but shall not be obligated to) retry to collect at a later time, and/or suspend or cancel the Account, without notice.&nbsp;</p>



<p>The Subscription Fees are exclusive of any and all taxes (including without limitation, value added tax, sales tax, use tax, excise, goods and services tax, etc.), levies, or duties, which may be imposed in respect of these Terms and the purchase or sale, of the Service hereunder (the “Taxes”), except for Taxes imposed on our income. You shall bear the sole responsibility and liability to pay such Taxes and such Taxes should be deemed as being added on top of the Subscription Fees, payable by you.</p>



<ol>
<li><strong>Use Restrictions.</strong></li>
</ol>



<p>Except as expressly permitted in these Terms, as a specific condition of your use of the Sites or the Service, you may not, and shall not allow another user or any third party to:</p>



<ul>
<li>use any part of the Sites or Service for any purpose that is unlawful or prohibited by these Terms;&nbsp;</li>



<li>intentionally submit or transmit inaccurate or false information through the Sites or Service;&nbsp;</li>



<li>impersonate or pretend to be anyone else while using the Sites or Service;&nbsp;</li>



<li>use the Sites or Service in any way that could damage, disable, overburden, or impair any part of the Sites or Service, or interfere with the integrity or proper working of the Sites or Service or anyone else’s use of the Sites or Service or interfere, overburden, damage, or impair other systems, programs, and platforms, such as making multiple e-mail or server requests, as determined by us;&nbsp;</li>



<li>attempt to gain unauthorized access to MLM’s computer systems or networks connected to MLM, through hacking, password mining or any other means;&nbsp;</li>



<li>reverse engineer, decompile or disassemble, decrypt or, attempt to derive the source code of, any portion of the Sites or Service or any components thereof or attempt to infringe the intellectual property rights of others in any way;&nbsp;</li>



<li>obtain or attempt to obtain any materials or information through any means not intentionally made available through any of the Sites or Service;&nbsp;</li>



<li>uses tools which anonymize your internet protocol address (e.g. anonymous proxy) to access the Sites or Service;</li>



<li>copy, modify, translate, patch, improve, alter, change or create any derivative works of the Service or Sites, or any part thereof;</li>



<li>circumvent, disable or otherwise interfere with security-related features of the Sites or Service or features that prevent or restrict use or copying of any content or that enforce limitations on use of the Service or Sites or attempt to circumvent any content-filtering techniques we employ or attempt to access any feature or area of our Sites or Service that you are not authorized to access;</li>



<li>introduce any malicious or technologically harmful material into our Sites or Service;&nbsp;</li>



<li>remove, deface, obscure, or alter MLM or any third party’s identification, attribution or copyright notices, trademarks, or other proprietary rights affixed to or provided as part of the Service or Sites, or use or display logos of the Service or Sites without MLM’s prior written approval;</li>



<li>give, sell, rent, lease, timeshare, sublicense, disclose, publish, assign, market, resell, display, transmit, broadcast, transfer or distribute any portion of the Service or the Sites to any third party, including, but not limited to your affiliates, or use the Sites or Service in any service bureau arrangement;</li>



<li>develop or use any third-party applications that interact with our Sites or Service without our prior written consent, including any scripts designed to scrape or extract data from our Sites or Service;&nbsp;</li>



<li>use the Service or Sites for competitive purposes, including to develop or enhance a competing service or product;&nbsp;</li>



<li>use our Sites or Service for any illegal or unauthorized purpose, or engage in, encourage or promote any activity that infringe or violates these Terms;&nbsp;</li>



<li>use the Sites and/or the Service for any purpose for which the Sites and/or the Service are not intended, such as sending unsolicited bulk message or e-mails in violation of anti-spam legislation (e.g. CAN-SPAM or other similar legislation in different jurisdictions); or</li>



<li>encourage or assist any third party (including other users) to do any of the foregoing.</li>
</ul>



<p>Failure to comply with any of the bulleted list in this Section may result in suspension and/or termination of your Account and may expose you to civil and criminal liability.</p>



<ol>
<li><strong>Termination, Effects of Termination, &amp; Suspension.&nbsp;</strong></li>
</ol>



<p>Your failure to comply with the provisions of these Terms shall terminate your license and these Terms. In the event of your failure to comply with these Terms, we may immediately temporarily or permanently limit, suspend or terminate your Account. If you object to any term hereof, as may be amended from time to time, or become dissatisfied with the Service, you may terminate these Terms at any time by ceasing to access and use the Sites and Service, and uninstalling any local shortcuts. This will be your sole remedy in such circumstances.&nbsp;</p>



<p>Upon termination or expiration of these Terms, your access and use, and all rights granted to you hereunder shall terminate. It is your sole obligation to export your Content prior to such termination or expiration. In the event that you did not delete your Content from your Account, we may continue to store and host it until either you or we, at our sole discretion, delete such Content, and during such period, you shall still be able to make a limited use of the Sites or Service in order to export the Content (the “Read-Only Mode”), but note that we are not under any obligation to maintain the Read-Only Mode period, hence such period may be terminated by us, at any time, with or without notice to you, and subsequently, your Content will be deleted. You acknowledge the foregoing and your sole responsibility to export and/or delete the Content prior to the termination or expiration of these Terms, and therefore we shall not have any liability either to you, nor to any other user or third party, in connection thereto. Unless expressly indicated herein otherwise, the termination or expiration of these Terms shall not relieve you from your obligation to pay due Subscription Fees.&nbsp;</p>



<p>We may at any time, at our sole discretion, cease the operation of the Service or any part thereof temporarily or permanently, delete any information from the Sites and/or the Service, or correct, modify, amend, enhance, improve and make any other changes thereto or discontinue displaying or providing any information, content or features therein, without giving any prior notice. Provided that such action is not related to a change required by applicable law or is related (directly or indirectly) to the security of the Sites and Service, if we take any action described in the first sentence of this paragraph, we will undertake our best effort to communicate those actions to you in advance by sending a communication to the contact information you have provided to us as part of your Account registration. Additionally, we will post in a reasonably conspicuous location on our Sites a description of the actions we intend to take regarding any material reduction in the Service you receive with at least seven (7) days prior notice of the material reduction unless such material reduction is required by applicable law or is related (directly or indirectly) to the security of the Sites and Service in either case we may not post a notice. You agree and acknowledge that MLM does not assume any responsibility with respect to or in connection with the termination of the Site’s and/or the Service’s operation and loss of any data.</p>



<p>We may decide to temporarily suspend or terminate your Account and/or your access to our Sites or Service, if the one or more of following events occur: (i) we believe, at our sole discretion, that you or any third party, are using the Service in a manner that may impose a security risk, may cause harm to us or any third party, and/or may raise any liability for us or any third party; (ii) we believe, at our sole discretion, that you or any third party, are using the Sites or Service in breach of these Terms or applicable law; (iii) your payment obligations, in accordance with these Terms, are or are likely to become, overdue; or (iv) your breach of the Use Restrictions above. The aforementioned suspension and termination rights are in addition to any remedies that may be available to us in accordance with these Terms and/or applicable law. Termination of these Terms under this Section shall not relieve you from your obligation to pay any Subscription Fees due.</p>



<ol>
<li><strong>Privacy</strong>.</li>
</ol>



<p>In order to use the Service, you acknowledge and agree that you will be required to provide us with certain information, including personally identifiable information. MLM’s <a href="https://matanslifemap.com/privacy-policy/">Privacy Policy can be found here</a>. It describes our policies and practices and the type of information we collect. By entering into these Terms, you represent that you have read, understand, and agree to MLM’s Privacy Policy, which is fully incorporated into these Terms by reference. If you do not agree to the terms and conditions of the Privacy Policy then you are not authorized to access and use the Sites or Service and must immediately cease all access.</p>



<ol>
<li><strong>Third-Party Software and Services.&nbsp;</strong></li>
</ol>



<p>Through the Sites or Service, you may enable integrations within your Account with Third-Party Services (as defined below), which may provide for interaction and exchange of data (including User Content) between the Sites or Service and the Third-Party Services. By integrating and/or using the Third-Party Services, you acknowledge and agree that: (a) you are solely responsible for your compliance with applicable privacy restrictions, laws and regulations, including your use of the Third-Party Services and other data activities you may conduct or may permit third parties, including the Third-Party Services, to conduct; (b) the activities and use of the Sites or Service by you and any other users, may result in a modification and/or removal of data (i.e. User Content) in the integrated Third-Party Service. We shall have no obligation of any kind, for any such modification and/or removal of data, either in your use of the Sites or Service and/or the integrated Third-Party Services. Both MLM and a provider of Third-Party Services may impose, each at its sole discretion, additional conditions or limitations on your access and use of certain Third-Party Services, including without limitation, imposing a limited quota on the number of actions or other uses (as the case may be). Such additional conditions or limitations shall be indicated wherever relevant within the Sites or Service or the Third-Party Services or otherwise identified for you.</p>



<p>MLM may provide links, integrations, or access to other websites, application, products or services provided by third-party service providers (“Third-Party Services”) when you use the Service or visit the Sites. MLM has no control over the content or privacy policies of Third-Party Services that you may link to from the Service or the Sites or their advertisers. If you visit a linked website, be aware that the third party operating any such website may have access to any information you submit via that website. MLM is not responsible for any third party’s failure to establish or abide by its or our Privacy Policy. Check the privacy policy for each website that you visit prior to submitting any personal information. Links to third-party websites do not imply endorsement of the websites by MLM. MLM makes no representations or warranties with respect to Third-Party Services and will not be liable for such Third-Party Services, even when used in conjunction with the Service.</p>



<ol>
<li><strong>Copyright Complaints</strong>.&nbsp;</li>
</ol>



<p>We respect the intellectual property rights of others and expect our users to do the same. If you, as a copyright owner or as a representative authorized to act on behalf of a copyright owner, feel, in good faith, that any information posted on our Sites or Service violates your rights under U.S. copyright law please report the alleged infringement by following the steps set forth in our <a href="https://matanslifemap.com/dmca-policy/">Copyright Notice</a>.&nbsp;</p>



<ol>
<li><strong>Intellectual</strong> <strong>Property</strong></li>
</ol>



<p>The Service and Sites, inclusive of materials, such as software, source and object code, application programming interface, GUI, design, text, editorial materials, informational text, designs, photographs, illustrations, audio clips, video clips, artwork and other graphic materials, and names, logos, trademarks and service marks (excluding Content), any and all related or underlying technology and any modifications, enhancements or derivative works of the foregoing (collectively, the “Materials”), are the property of MLM and its licensors, and are protected by applicable copyright, trademark, and other intellectual property laws. All rights not expressly granted to you hereunder are reserved by MLM and its licensors, and as between you and MLM, MLM retains all right, title and interest, including all intellectual property rights, in and to the Materials. The Service is controlled and operated by MLM from its offices within the U.S. MLM makes no representation that any of the Service or Materials are appropriate or available for use in other locations, and access to them from territories where their contents are illegal is prohibited. Those who choose and access any part of the Service from other locations does so on their own initiative and are responsible for compliance with applicable local laws.</p>



<p>Notwithstanding any other provision of these Terms, we may collect, use and publish Anonymous Information (defined below) relating to your use of the Service and/or Sites, and disclose it for the purpose of providing, improving and publicizing our products and services, including the Sites and Service, and for other business purposes. “Anonymous Information” means information which does not enable identification of an individual, such as aggregated and analytics information. MLM owns all Anonymous Information collected or obtained by MLM.</p>



<p>As a user of the Service and/or Sites, you may provide suggestions, comments, feature requests or other feedback to any of the Materials, the Service, and/or the Sites (“Feedback”). Such Feedback is deemed an integral part of the Materials, and as such, it is the sole property of MLM without restrictions or limitations on use of any kind. MLM may either implement or reject such Feedback, without any restriction or obligation of any kind. You (i) represent and warrant that such Feedback is accurate, complete, and does not infringe on any third party rights; (ii) irrevocably assign to MLM any right, title and interest you may have in such Feedback; and (iii) explicitly and irrevocably waive any and all claims relating to any past, present or future moral rights, artists’ rights, or any other similar rights, worldwide, in or to such Feedback.</p>



<ol>
<li><strong>Trademarks and Trade names</strong></li>
</ol>



<p>MLM’s marks and logos and all other proprietary identifiers used by MLM in connection with the Sites and Service (“MLM Trademarks”) are all trademarks and/or trade names of MLM, whether or not registered. All other trademarks, service marks, trade names and logos that may appear on the Sites and/or the Service belong to their respective owners (“Third-Party Marks”). No right, license, or interest to MLM Trademarks and the Third-Party Marks is granted hereunder, and you agree that no such right, license or interest shall be asserted by you with respect to MLM Trademarks or Third-Party Marks and therefore you will avoid using any of those marks. In addition, the look and feel of the Sites and the Service including all page headers, custom graphics, button icons and scripts, is the service mark, trademark and/or trade dress of ours and may not be copied, imitated or used, in whole or in part, without our prior written permission. Reference to any products, services, processes or other information, by trade name, trademark, manufacturer, supplier or otherwise does not constitute or imply endorsement, sponsorship or recommendation thereof by us or affiliation with us.</p>



<ol>
<li><strong>Security</strong>.</li>
</ol>



<p>MLM has and at all times shall maintain an information security program that includes reasonable administrative, electronic, technical, physical and other security measures and safeguards reasonably designed, at a minimum, to: (a) ensure the security and confidentiality of the all Content and the information contained in the Sites and the Service; (b) protect against any unauthorized access to or use of the Sites and the Service; (c) protect against any anticipated threats or hazards to the security or integrity of the Sites and the Service; and (d) ensure the proper, secure and lawful disposal of confidential information within its possession or control.</p>



<ol>
<li><strong>Export Controls; Sanctions.</strong></li>
</ol>



<p>The Service may be subject to U.S. or foreign export controls, laws and regulations (the “Export Controls”), and you acknowledge and confirm that: (i) you are not located or use, export, re-export or import the Service (or any portion thereof) in or to, any person, entity, organization, jurisdiction or otherwise, in violation of the Export Controls; (ii) you are not: (a) organized under the laws of, operating from, or otherwise ordinarily reside in a country or territory that is the target of comprehensive U.S. economic or trade sanctions, (b) identified on a list of prohibited or restricted persons, such as the U.S. Treasury Department’s List of Specially Designated Nationals and Blocked Persons, or (c) otherwise the target of U.S. sanctions. You are solely responsible for complying with applicable Export Controls and sanctions which may impose additional restrictions, prohibitions or requirements on the use, export, re-export or import of the Service and/or the Content; and (iii) Content is not controlled under the U.S. International Traffic in Arms Regulations or similar laws in other jurisdictions, or otherwise requires any special permission or license, in respect of its use, import, export or re-export hereunder.</p>



<ol>
<li><strong>Limitation of Liability</strong>.</li>
</ol>



<p>NOTWITHSTANDING ANYTHING IN THESE TERMS OR ELSEWHERE TO THE CONTRARY AND TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, EXCEPT FOR YOUR FRAUD, GROSS NEGLIGENCE, YOUR INDEMNITY OBLIGATIONS, YOUR BREACH OF SECTION 10 (INTELLECTUAL PROPERTY) OR SECTION 11 (TRADEMARKS AND TRADENAMES) OR BREACH OF YOUR OBLIGATIONS OF CONFIDENTIALITY, IN NO EVENT SHALL EITHER PARTY HERETO AND ITS AFFILIATES, SUBCONTRACTORS, AGENTS AND VENDORS (INCLUDING, THE THIRD-PARTY SERVICES PROVIDERS), BE LIABLE UNDER, OR OTHERWISE IN CONNECTION WITH THESE TERMS FOR (I) ANY INDIRECT, EXEMPLARY, SPECIAL, CONSEQUENTIAL, INCIDENTAL OR PUNITIVE DAMAGES; (II) ANY LOSS OF PROFITS, COSTS, ANTICIPATED SAVINGS; (III) ANY LOSS OF, OR DAMAGE TO DATA, USE, BUSINESS, REPUTATION, REVENUE OR GOODWILL; AND/OR (IV) THE FAILURE OF SECURITY MEASURES AND PROTECTIONS, WHETHER IN CONTRACT, TORT OR UNDER ANY OTHER THEORY OF LIABILITY OR OTHERWISE, AND WHETHER OR NOT SUCH PARTY HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES IN ADVANCE, AND EVEN IF A REMEDY FAILS OF ITS ESSENTIAL PURPOSE.</p>



<p>IN ANY CASE, WITHOUT LIMITING THE GENERALITY OF THE FOREGOING AND TO THE MAXIMUM EXTENT LEGALLY PERMISSIBLE, THE TOTAL AGGREGATE LIABILITY OF MLM, ITS AFFILIATES, SUBCONTRACTORS, AGENTS AND VENDORS (INCLUDING, THE ITS THIRD-PARTY SERVICES PROVIDERS), UNDER, OR OTHERWISE IN CONNECTION WITH, YOUR USE OR INABILITY TO USE THE SITES OR SERVICE OR ANY CONTENT EXCEED THE TOTAL AMOUNT OF FEES ACTUALLY PAID BY YOU (IF ANY) DURING THE 12 CONSECUTIVE MONTHS PRECEDING THE EVENT GIVING RISE TO SUCH LIABILITY OR $100.00 WHICHEVER IS GREATER.&nbsp;</p>



<ol>
<li><strong>Disclaimer</strong>.</li>
</ol>



<p>NOTWITHSTANDING ANYTHING IN THESE TERMS OR ELSEWHERE TO THE CONTRARY AND TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, EXCEPT AS EXPRESSLY SET FORTH HEREIN, THE SITES AND THE SERVICE ARE PROVIDED ON AN “AS IS”, “WITH ALL FAULTS” AND “AS AVAILABLE” BASIS, AND WITHOUT WARRANTIES OF ANY KIND. WE AND OUR AFFILIATES, SUBCONTRACTORS, AGENTS AND VENDORS INCLUDING, THE THIRD-PARTY SERVICES PROVIDERS, HEREBY DISCLAIM ANY AND ALL REPRESENTATIONS AND WARRANTIES OF ANY KIND, INCLUDING WITHOUT LIMITATION, WARRANTIES AND/OR REPRESENTATIONS OF MERCHANTABILITY, FUNCTIONALITY, TITLE, FITNESS FOR A PARTICULAR PURPOSE AND NON-INFRINGEMENT, WHETHER EXPRESS, IMPLIED OR STATUTORY.</p>



<p>WE DO NOT WARRANT, AND EXPRESSLY DISCLAIM ANY WARRANTY OR REPRESENTATION THAT THE SERVICE AND SITES, INCLUDING THE ACCESS THERETO AND USE THEREOF, WILL BE UNINTERRUPTED, TIMELY, SECURED, ERROR FREE, THAT DATA WON’T BE LOST, THAT DEFECTS WILL BE CORRECTED, OR THAT THE SITES AND/OR SERVICE ARE FREE FROM VIRUSES OR OTHER HARMFUL CODE. WE FURTHER DISCLAIM ANY AND ALL LIABILITY OR RESPONSIBILITY FOR ANY DELAYS, FAILURES, INTERCEPTION, ALTERATION, LOSS, OR OTHER DAMAGES THAT YOU AND/OR YOUR DATA (INCLUDING CONTENT) MAY SUFFER, THAT ARE BEYOND OUR CONTROL.</p>



<p>EXCEPT AS EXPRESSLY SET FORTH HEREIN, WE DO NOT WARRANT, AND EXPRESSLY DISCLAIM ANY WARRANTY OR REPRESENTATION (I) THAT OUR SERVICE (OR ANY PORTION THEREOF) IS COMPLETE, ACCURATE, OF ANY CERTAIN QUALITY, RELIABLE, SUITABLE FOR, OR COMPATIBLE WITH, ANY OF YOUR CONTEMPLATED ACTIVITIES, DEVICES, OPERATING SYSTEMS, BROWSERS, SOFTWARE OR TOOLS (OR THAT IT WILL REMAIN AS SUCH AT ANY TIME), OR COMPLY WITH ANY LAWS APPLICABLE TO YOU; AND/OR (II) REGARDING ANY CONTENT, INFORMATION, REPORTS OR RESULTS THAT YOU OBTAIN THROUGH THE SERVICE AND/OR THE SITES.</p>



<p>Except as expressly stated in these Terms, we make no representations or warranties that your use of the Service is appropriate in your jurisdiction. Other than as indicated herein, you are responsible for your compliance with any local and/or specific applicable laws, as applicable to your use of the Service.</p>



<p>You agree that the use of the Sites and/or the Service is entirely at your own risk.</p>



<ol>
<li><strong>Indemnification</strong>.&nbsp;</li>
</ol>



<p>You will indemnify and hold MLM, its affiliates and their respective officers, directors, employees and agents, harmless from and against any claims, disputes, demands, liabilities, damages, losses, and costs and expenses, including, without limitation, reasonable legal fees arising out of or in any way connected with (a) your access to and/or use of the Sites and the Service; (b) your Content, including the use of Content by MLM and/or any of its subcontractors; (c) your violation of these Terms; (d) your violation of any third-party rights, including without limitation any intellectual property rights or privacy rights of such third parties with respect to your use of the Service; and (e) any damage of any sort, whether direct, indirect, special or consequential, that you may cause to any third party with relation to the Sites and/or the Service. We will provide notice to you of any such claim, dispute or demand. We reserve the right, at your expense, to assume the exclusive defense and control of any claim, dispute or demand that is subject to indemnification under this Section. In such case, you agree to cooperate with any reasonable requests assisting our defense of such matter.</p>



<ol>
<li><strong>Arbitration</strong>.&nbsp;</li>
</ol>



<p>Except for any controversy, disputes, claims, suits, actions, causes of action, demands, or proceedings (collectively, “Disputes”) arising out of or related to a violation these Terms or Disputes in which either party seeks to bring an individual action in small claims court or seeks injunctive or other equitable relief for the alleged unlawful use of intellectual property, including, without limitation, copyrights, trademarks, trade names, logos, trade secrets, or patents, you and MLM agree to submit such Dispute to arbitration administered by the International Centre for Dispute Resolution in accordance with its International Arbitration Rules.&nbsp;</p>



<p>Nothing in these Terms will be deemed to waive, preclude, or otherwise limit the right of MLM to: (a) bring an individual action in small claims court; (b) pursue an enforcement action through the applicable federal, state, or local agency if that action is available; or (c) to file suit in a court of law to address an intellectual property infringement claim.&nbsp;</p>



<ol>
<li><strong>General Provisions.</strong></li>
</ol>



<p><strong>Choice of Law; Jurisdiction</strong>.</p>



<p>These Terms will be governed by and construed in accordance with the laws of the State of Delaware (without reference to the conflicts of laws provisions thereof that would result in the application of another law). Any action, suit, or other legal proceeding which is commenced to resolve any matter arising under or relating to any provision of these Terms will be commenced only in a court of the State of Delaware (or, if appropriate, a federal court located within the State of Delaware) and MLM and you each consents to the jurisdiction of such a court. MLM and you each hereby irrevocably waive any right to a trial by jury in any action, suit or other legal proceeding arising under or relating to any provision of these Terms. Notwithstanding the foregoing, MLM reserves the right to seek injunctive relief in any court in any jurisdiction.</p>



<p><strong>No Class Action.</strong></p>



<p>WHERE PERMITTED UNDER APPLICABLE LAW, YOU AND MLM AGREE THAT EACH PARTY MAY BRING CLAIMS AGAINST THE OTHER PARTY ONLY IN YOUR OR ITS INDIVIDUAL CAPACITY AND NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED CLASS OR REPRESENTATIVE ACTION. Unless both you and MLM mutually agree in writing, no arbitrator or judge may consolidate more than one person’s claims or otherwise preside over any form of a representative or class proceeding.</p>



<p><strong>Arbitration and Class Action Notice and Opt-Out</strong></p>



<p>You have the right to opt out and not be bound by the arbitration (Section 17) and class-action-waiver provisions (Section 18) set forth above by sending written notice to the address below. The notice must be sent within 30 days of registering to use the Service; otherwise, you shall be bound to arbitrate disputes in accordance with these Terms. If you opt out of these arbitration provisions, MLM also will not be bound by them. If you opt out, all other parts of these Terms will continue to apply to you.</p>



<p><strong>Modifications</strong>.&nbsp;</p>



<p>From time to time, we may make changes to these Terms for valid reasons, such as adding new functions or features to the Sites or Service, technical adjustments, typos or error fixing, for legal or regulatory reasons or for any other reasons as we deem necessary, at our sole discretion. When we make material changes to these Terms, we will provide you with notice as appropriate under the circumstances, e.g., by displaying a prominent notice within the Sites or Service or by sending you an email. Your continued use of the Sites or Service after the changes have been implemented will constitute your acceptance of the changes and the updated Terms.</p>



<p><strong>Force Majeure</strong>.</p>



<p>Neither us nor you will be liable by reason of any failure or delay in the performance of its obligations on account of events beyond the reasonable control of a party, which may include denial-of-service attacks, interruption or failure of the Internet or any utility service, failures in third-party hosting services, strikes, shortages, riots, fires, acts of God, war, terrorism, and governmental action.</p>



<p><strong>Relationship of the Parties; No Third Party Beneficiaries</strong>.</p>



<p>The parties are independent contractors. These Terms and the Sites and Service provided hereunder, do not create a partnership, franchise, joint venture, agency, fiduciary or employment relationship between the parties. There are no third-party beneficiaries to these Terms.</p>



<p><strong>Notice</strong>.</p>



<p>We shall use your contact details that we have in our records, in connection with providing you notices, subject to this Section. Our contact details for any notices are detailed below. You acknowledge that notices we provide you in connection with these Terms and/or as otherwise related to the Sites or Service shall be provided as follows: via the Service, including by posting on our Sites or posting in your Account, text, e-mail, phone or first class, airmail, or overnight courier. You further acknowledge that an electronic notification satisfies any applicable legal notification requirements, including that such notification will be in writing. Any notice to you will be deemed given upon the earlier of: (i) receipt; or (ii) 24 hours of delivery. Notices to us shall be provided to Matan’s Life Map, Inc.., Attn: COO, at contact@matanslifemap.com.</p>



<p><strong>Assignment</strong>.</p>



<p>These Terms, and any and all rights and obligations hereunder, may not be transferred or assigned by you without our prior written approval. We may assign our rights and/or obligations hereunder and/or transfer ownership rights and title in the Sites or Service to a third party without your consent or prior notice to you. Subject to the foregoing conditions, these Terms shall bind and inure to the benefit of the parties, their respective successors, and permitted assigns. Any assignment not authorized under this Section shall be null and void.</p>



<p><strong>Severability</strong>.</p>



<p>These Terms shall be enforced to the fullest extent permitted under applicable law. If any provision of these Terms is held by a court of competent jurisdiction to be contrary to law, the provision will be modified by the court and interpreted so as best to accomplish the objectives of the original provision to the fullest extent permitted by law, and the remaining provisions of these Terms will remain in effect.</p>



<p><strong>Survival.</strong></p>



<p>Sections 5, 6, 7, and 9-18 shall survive termination or expiration of these Terms and continue to be in force and effect.</p>



<p><strong>No Waiver</strong>.</p>



<p>No failure or delay by either party in exercising any right under these Terms will constitute a waiver of that right. No waiver under these Terms will be effective unless made in writing and signed by an authorized representative of the party being deemed to have granted the waiver.</p>



<p><strong>Contact</strong>.&nbsp;</p>



<p>If you have any questions about these Terms or the Sites or Service you can contact us at contact@matanslifemap.com.</p>

</div>
      </IonContent>
    </IonPage>
  );
};

export default Terms;
