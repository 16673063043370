import axios from 'axios';
import { User } from '../interfaces/User';

// const localdata = localStorage.getItem("user") as string ;
// const localuser = JSON.parse(localdata);


const API= process.env.REACT_APP_API_URL;
const APIKEY = process.env.REACT_APP_API_KEY ;

export interface UserContainer<T>{
    data: T;
    success:boolean;
    statusCode:number;
    code:string;
    message:string;
}
const mlmApi = axios.create({
    baseURL: API,
    headers: {
        'Content-Type': 'application/json',
        // 'Authorization': getAuthorizationHeader()
    },
});
mlmApi.interceptors.request.use(function (config) {
    let token = localStorage.getItem('access_token')

    if (token) {
        config.headers.Authorization = `Bearer ${token}`
    }

    return config;
  }, function (error) {
    // Do something with request error
    return Promise.reject(error);
  });
mlmApi.interceptors.response.use((res) => {
 
    return res;
  }, function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    // return Promise.reject(error);
    if (error.response) {
        return error.response;
    }
      
    
  });
export function getQuestions(): Promise<any> {
    
    const questions = [
            {
                'id':1,
                'type':'text',
                'title':'Daily Finances',
                'q':'Where do you bank? What credit / debit cards do you have? Any digital accounts (e.g. PayPal, Wise)? Note: You don’t need passwords or account numbers, just the right places to contact in case your loved ones need to reach out.',
                'badges':[
                    'bank accounts', 'credit cards', 'debit cards', 'digital accounts'
                ],
                'a':'',
                'audio':'https://app.matanslifemap.com/assets/sounds/Q1-31122022.mp3',
                'video':'https://app.matanslifemap.com/assets/videos/Q01_ANIM_V2.mp4',
                'videowebm':'https://app.matanslifemap.com/assets/videos/Q01_ANIM_V2.webm',
                'bgcolor':'#0A0C43',
                'bgimage':'https://app.matanslifemap.com/assets/videos/Q1-Illus.jpg',
                'txtcolor':'white'
            },
            {
                'id':2,
                'type':'text',
                'title':'Investments',
                'q':'Do you have any investments? Does someone manage your money or do your taxes? Do you have an annuity? What about saving accounts, retirement accounts, college funds, brokerage, stocks, bonds, crypto, pension, life insurance savings. Any businesses? Partnerships? Did you give a loan to anyone?\n\nNote: Focus on the institution name and/or contact person – that’ll be enough to get your loved ones started.',
                'badges':[
                    'investments', 'money manager', 'tax preparer', 'annuity', 'savings', 'brokerage', 'stocks', 'bonds', 'crypto', 'pension plan', 'life insurance savings', 'partnerships', 'companies'
                ],
                'a':'',
                'audio':'https://app.matanslifemap.com/assets/sounds/Q2-31122022.mp3',
                'video':'https://app.matanslifemap.com/assets/videos/Q02_ANIM_V2.mp4',
                'videowebm':'https://app.matanslifemap.com/assets/videos/Q02_ANIM_V2.webm',
                'bgcolor':'#100D64',
                'bgimage':'https://app.matanslifemap.com/assets/videos/Q2-Illus.jpg',
                'txtcolor':'white'
            },
            {
                'id':3,
                'type':'text',
                'title':'Insurances',
                'q':'Who provides your health coverage? Where do you insure your vehicles and your home? What about any life, long-term care, or liability policies? Is your pet or any of your assets insured?\n\nNote: Company names or agents will be the most helpful here.',
                'badges':[
                    'health insurance', 'car insurance', 'home insurance', 'life insurance', 'long-term care insurance', 'liability insurance', 'other insurances'
                ],
                'a':'',
                'audio':'https://app.matanslifemap.com/assets/sounds/Q3-31122022.mp3',
                'video':'https://app.matanslifemap.com/assets/videos/Q03_ANIM_V2.mp4',
                'videowebm':'https://app.matanslifemap.com/assets/videos/Q03_ANIM_V2.webm',
                'bgcolor':'#0F1E12',
                'bgimage':'https://app.matanslifemap.com/assets/videos/Q3-Illus.jpg',
                'txtcolor':'white'
            },
            
            {
                'id':4,
                'type':'text',
                'title':'Your Home',
                'q':'Do you own a home or any other real estate investments? Do you work with a property management company for any of them? Where is your deed? Are there any liens on your property or is money owed to you? Do you still make mortgage payments? Who is the lender? What about if you rent? Who is your landlord? Note: Make sure to provide addresses and contact information.',
                'badges':[
                    'house', 'real estate investments', 'rent', 'addresses', 'key contact person', 'mortgage', 'liens', 'deeds'
                ],
                'a':'',
                'audio':'https://app.matanslifemap.com/assets/sounds/Q4-31122022.mp3',
                'video':'https://app.matanslifemap.com/assets/videos/Q04_ANIM_V2.mp4',
                'videowebm':'https://app.matanslifemap.com/assets/videos/Q04_ANIM_V2.webm',
                'bgcolor':'#325119',
                'bgimage':'https://app.matanslifemap.com/assets/videos/Q4-Illus.jpg',
                'txtcolor':'white'
            },
            {
                'id':5,
                'type':'text',
                'title':'Car & Other Valuables',
                'q':'Do you own a car? Is it financed through a loan? Where is the title? Even the simple stuff – where do you store the spare set of keys?\n\nDo you have any other valuables (jewelry, memorabilia, boat, art etc.)?\n\nAny other safe storage: Safety deposit box, home safe, storage unit, mattress…?',
                'badges':[
                    'car', 'car loan', 'title', 'spare keys', 'other valuables', 'safe storage'
                ],
                'a':'',
                'audio':'https://app.matanslifemap.com/assets/sounds/Q5-31122022.mp3',
                'video':'https://app.matanslifemap.com/assets/videos/Q05_ANIM_V2.mp4',
                'videowebm':'https://app.matanslifemap.com/assets/videos/Q05_ANIM_V2.webm',
                'bgcolor':'#2924CF',
                'bgimage':'https://app.matanslifemap.com/assets/videos/Q5-Illus.jpg',
                'txtcolor':'white'
            },
            {
                'id':6,
                'type':'text',
                'title':'The “Stash”',
                'q':'Many people have a “stash” that at least keeps some important documents all in one place, whether it’s a drawer, a filing cabinet, or a lockbox. Is a key or code needed to access it? What kind of information do you keep in there – insurance or banking paperwork? Legal documents? Note: If you don’t have this, just be honest – say so, but then get started pulling one together.',
                'badges':[],
                'a':'',
                'audio':'https://app.matanslifemap.com/assets/sounds/Q6-31122022.mp3',
                'video':'https://app.matanslifemap.com/assets/videos/Q06_ANIM_V2.mp4',
                'videowebm':'https://app.matanslifemap.com/assets/videos/Q06_ANIM_V2.webm',
                'bgcolor':'#5028C5',
                'bgimage':'https://app.matanslifemap.com/assets/videos/Q6-Illus.jpg',
                'txtcolor':'white'
            },
            {
                'id':7,
                'type':'text',
                'title':'Key People',
                'q':'Who should be contacted if something happens to you? Who can be helpful to your loved ones? Include friends and family, but also consider lawyers, accountants, executors, landlords, financial advisors, employers. What about community or spiritual leaders? Note: Provide names and contact information for these people – phone numbers and email addresses are both great.',
                'badges':[],
                'a':'',
                'audio':'https://app.matanslifemap.com/assets/sounds/Q7-31122022.mp3',
                'video':'https://app.matanslifemap.com/assets/videos/Q07_ANIM_V2.mp4',
                'videowebm':'https://app.matanslifemap.com/assets/videos/Q07_ANIM_V2.webm',
                'bgcolor':'#F86F64',
                'bgimage':'https://app.matanslifemap.com/assets/videos/Q7-Illus.jpg',
                'txtcolor':'black'
            },
            {
                'id':8,
                'type':'text',
                'title':'My Loves',
                'q':'Does anyone rely on you for care? Pets or children? Elderly family members? If something happens, what needs to be known about their care? Be sure to consider medication and routines. This is a good place to note down any volunteer or charity work you may be a part of, too. ',
                'badges':[],
                'a':'',
                'audio':'https://app.matanslifemap.com/assets/sounds/Q8-31122022.mp3',
                'video':'https://app.matanslifemap.com/assets/videos/Q08_ANIM_V2.mp4',
                'videowebm':'https://app.matanslifemap.com/assets/videos/Q08_ANIM_V2.webm',
                'bgcolor':'#EB9479',
                'bgimage':'https://app.matanslifemap.com/assets/videos/Q8-Illus.jpg',
                'txtcolor':'black'
            },
            {
                'id':9,
                'type':'text',
                'title':'If I Can’t',
                'q':'On a very serious note, consider an accident, maybe a coma – you’re alive but unable to make decisions. What do you want to happen – keep you alive at all costs? You may want to consider getting a medical directive, health proxy, or power of attorney if this is not organized yet. Note any of those documents here, or explain what your final wishes would be.',
                'badges':[
                    'If I can’t documents','final wishes',
                ],
                'a':'',
                'audio':'https://app.matanslifemap.com/assets/sounds/Q9-31122022.mp3',
                'video':'https://app.matanslifemap.com/assets/videos/Q09_ANIM_V2.mp4',
                'videowebm':'https://app.matanslifemap.com/assets/videos/Q09_ANIM_V2.webm',
                'bgcolor':'#22ACFF',
                'bgimage':'https://app.matanslifemap.com/assets/videos/Q9-Illus.jpg',
                'txtcolor':'black'
            },
            {
                'id':10,
                'type':'text',
                'title':'Key Documents',
                'q':'What other important records do you have? Consider wills, tax returns, burial arrangements, and the like. This is also a great place to capture any other documents that haven’t been noted in the previous questions. For example, deeds, stock certificates, corporate operating agreements, warranties etc.\n\nNote: If you don’t have a will, say so – you’re not alone. This is a good reminder to get started on getting one, though.',
                'badges':[
                    'wills','other key docs'
                ],
                'a':'',
                'audio':'https://app.matanslifemap.com/assets/sounds/Q10-31122022.mp3',
                'video':'https://app.matanslifemap.com/assets/videos/Q10_ANIM_V2.mp4',
                'videowebm':'https://app.matanslifemap.com/assets/videos/Q10_ANIM_V2.webm',
                'bgcolor':'#41DFE1',
                'bgimage':'https://app.matanslifemap.com/assets/videos/Q10-Illus.jpg',
                'txtcolor':'black'
            },
            {
                'id':11,
                'type':'text',
                'title':'Key Passwords',
                'q':'We emphasize that you should NOT include any passwords in Matan’s Life Map directly. Instead, note where a list that contains any passwords/usernames is stored. Are they stuck on page 235 of The Joy of Cooking? That’s the information your loved ones need.\n\nNote: Your loved ones ONLY need a few key passwords/usernames (Mobile, laptop, Gmail, facebook etc) to have access and ability to reset all other passwords.',
                'badges':[
                    'password list location'
                ],
                'a':'',
                'audio':'https://app.matanslifemap.com/assets/sounds/Q11-31122022.mp3',
                'video':'https://app.matanslifemap.com/assets/videos/Q11_ANIM_V2.mp4',
                'videowebm':'https://app.matanslifemap.com/assets/videos/Q11_ANIM_V2.webm',
                'bgcolor':'#F1CF1A',
                'bgimage':'https://app.matanslifemap.com/assets/videos/Q11-Illus.jpg',
                'txtcolor':'black'
            },
            {
                'id':12,
                'type':'text',
                'title':' Loose Ends',
                'q':'Anything else? Everyone is unique, and you’ve had to think about a lot throughout this process. Go take a walk, take a deep breath and consider anything else you may want your loved ones to know about. The key here: avoid any “Oh $h!t” moments for them. ',
                'badges':[],
                'a':'',
                'audio':'https://app.matanslifemap.com/assets/sounds/Q12-31122022.mp3',
                'video':'https://app.matanslifemap.com/assets/videos/Q12_ANIM_V2.mp4',
                'videowebm':'https://app.matanslifemap.com/assets/videos/Q12_ANIM_V2.webm',
                'bgcolor':'#FEEC96',
                'bgimage':'https://app.matanslifemap.com/assets/videos/Q12-Illus.jpg',
                'txtcolor':'black'
            }
    ] as any;

    return questions;
      
}
export function getUserSummary() {
   
        return  mlmApi.get('user-summary').then( x=> x.data ); 

        // const data:any = [
        //     {"id":"1","title":"Daily Finances","answer":"dfsdfsd\n\ndebit cards: urtyert"},
        //     {"id":"2","title":"Investments","answer":"trhrt\n\ncrypto: hrthrth"},
        //     {"id":"3","title":"Insurances","answer":"trhh gherhe\n\nretherthrtep\n\nliability: rtherthrt"},
        //     {"id":"4","title":"The “house”","answer":"key contact person: "},
        //     {"id":"5","title":"Car & other valuables","answer":"gdfgdf h"},
        //     {"id":"6","title":"The Stash","answer":"dhgfdh hfdg dfgh"},
        //     {"id":"7","title":"Key People","answer":"fdgh dfndf"},
        //     {"id":"8","title":"My Loves","answer":" dfghndfgnn \n\nrther h"},
        //     {"id":"9","title":"If I Can’t","answer":"other thoughts: sdsad"},
        //     {"id":"10","title":"Key Docs","answer":"fh thrtmgm ghjdfh"},
        //     {"id":"11","title":"Super Key Passwords","answer":"fhjt jgh mrgmtm"},
        //     {"id":"12","title":"Anything Else?","answer":" dyjhf ngmrm"}
        // ];
            
        //     return data;
        
      
}

export function sharedTo(shared:any): Promise<any> {
    // console.log(shared);
    // return shared;
    return mlmApi.post('share-it', shared).then( x=> x.data ); 
}

export function getSharedTo(): Promise<any> {

    return  mlmApi.get('share-it').then( x=> x.data ); 
    // let shared = [
    //     { id:'dfgh',name: "Moshe", val: "+972501234567", type: "phone" },
    //     { id:'deeefgh',name: "Sylvie", val: "sylvie@gmail.com", type: "email" },
    // ]as any;
    // return shared;
}
export function deleteShare(value:string): Promise<any> {

    return  mlmApi.delete('share-it/'+value).then( x=> x.data ); 

}
export function deleteUser(): Promise<any> {

    return  mlmApi.delete('remove-account').then( x=> x.data ); 

}

export function sharedWithMeUsers(): Promise<any> {
    
    return  mlmApi.get('shared-with-me').then( x=> x.data ); 
    // let users = [
    //     {"id":'1234',"fullName": 'Moshe Cohen',"nickName": 'Moshe Cohen HAMELECH'},
    //     {"id":'7654',"fullName": 'Sylvie',"nickName": 'Sylvie frfgfg'},
    // ]as any;
    // return users;
}
export function sharedWithMeAnswers(userId:string): Promise<any> {
    console.log(userId);
    return  mlmApi.get('/view-user-summary/'+userId).then( x=> x.data ); 
    // let answer = [
    //     {"id":"1","title":"Daily Finances","answer":"dfsdfsd\n\ndebit cards: urtyert"},
    //     {"id":"2","title":"Investments","answer":"trhrt\n\ncrypto: hrthrth"},
    //     {"id":"3","title":"Insurances","answer":"trhh gherhe\n\nretherthrtep\n\nliability: rtherthrt"},
    //     {"id":"4","title":"The “house”","answer":"key contact person: "},
    //     {"id":"5","title":"Car & other valuables","answer":"gdfgdf h"},
    //     {"id":"6","title":"The Stash","answer":"dhgfdh hfdg dfgh"},
    //     {"id":"7","title":"Key People","answer":"fdgh dfndf"},
    //     {"id":"8","title":"My Loves","answer":" dfghndfgnn \n\nrther h"},
    //     {"id":"9","title":"If I Can’t","answer":"other thoughts: sdsad"},
    //     {"id":"10","title":"Key Docs","answer":"fh thrtmgm ghjdfh"},
    //     {"id":"11","title":"Super Key Passwords","answer":"fhjt jgh mrgmtm"},
    //     {"id":"12","title":"Anything Else?","answer":" dyjhf ngmrm"}
    //     ] as any;
    
    
    // return answer;
      
}
export function saveAnswers(userSummary:any) {
    // console.log('userSummary',userSummary);
    return mlmApi.post('user-summary', userSummary); 
}

export function getProfile(): Promise<any> {

    return  mlmApi.get('profile').then( x=> x.data.profile ); 

    // const UserProfile = {
    //     "fullName": 'Moshe Cohen',
    //     "nickName": 'Moshe Cohen HAMELECH',  
    //     "email": 'moshe@gmail.com',
    //     "phone": '+972546499444'
    //   } as any;

    // return UserProfile;
    
}
export function getPaidStatus(): Promise<any> {

    
    return  mlmApi.get('status-pay').then( x=> x.data.paid );
    // return  mlmApi.get('p-status').then( x=> x.data.paid ); 
    const p = localStorage.getItem('p') ;
    // let PaidStatus = {};
    // if (p){
    //     PaidStatus = {paid : JSON.parse(p)} 
    //     // PaidStatus = {paid : false} 
    // }   
    

    // return PaidStatus as any;
    
}
export function saveProfile(profile:any): Promise<any> {
// console.log("profile", profile)
    return mlmApi.post('profile', {
            "profile": {
                "fullName": profile.fullName,
                "nickName": profile.nickName,
                "firstName": profile.firstName,
                "lastName": profile.lastName,
                "email": profile.email,
                "phone": profile.phone,
            }
    }); 
  
    
}

export function sendKey(key:string) {
 console.log('key',key);
//  const shared = {
// 	originalUserName: "Sdahan",
// 	originalUserId:"I1NWr27zYpaPSQOjAiHNEGOFG152",
// 	isUser: true,
//     sharedType:"phone"
// };
// return shared;
    return mlmApi.post('shared', {
        "key":key
    }); 
  
    
}
export function sendOtp(key:string) {
    console.log('key',key);
   //  const shared = {
   // 	originalUserName: "Sdahan",
   // 	originalUserId:"I1NWr27zYpaPSQOjAiHNEGOFG152",
   // 	isUser: true,
   //     sharedType:"phone"
   // };
   // return shared;
       return mlmApi.post('send-otp', {
           "key":key
       }); 
     
       
   }

const endpoint = axios.create({
    baseURL: 'https://wtfismyip.com/',
    headers: {
        'Content-Type': 'application/json'
       
    },
});
endpoint.interceptors.response.use((response) => {
    if (response.status === 200) {
        // console.log(response.data);
        return response.data;
    } 
});

export async function getGeolocation(): Promise<any> {
    
    try {
        const response = await axios.get('https://wtfismyip.com/json');
        // console.log(response);
        return response.data;
      } catch (error) {
        // console.error(error);
        return error;
      }
    
  
      
}
export function sendResetPassword(data:any): Promise<UserContainer<User>> {
    console.log(data);
     return mlmApi.post('reset-forgot', data); 
     
      
 }
export function resetPassword(data:any): Promise<UserContainer<User>> {
   console.log(data);
    return mlmApi.post('forgot', {
        "code":data.target['codefromurl'].value,
        "newPassword":data.target['password'].value,
        }); 
    
     
}
export function sendSession(session:any){
    console.log(session);
    
     return mlmApi.post('deal-stripe', {
         "sessionId":session
         }); 
    //  const userSession = {
    //     paid: true,
    //     };
    //     return userSession;
     
      
 }
export function getToken(data:any): Promise<UserContainer<User>> {
   
    return mlmApi.post('login?key='+APIKEY, {
        email:data.target['email'].value,
        password:data.target['password'].value,
        returnSecureToken:true
    }); 
    
     
}
export function register(data:any): Promise<UserContainer<User>> {
//    console.log('register',data);
   return mlmApi.post('register', data); 
    
     
}
export function  checkToken() {
    
    const access_token =  (localStorage.getItem('access_token'))? localStorage.getItem('access_token') : null;
    if(access_token !== null){
        
        let tokenIsValid = validateToken();
        if (tokenIsValid) {
            return true;

        }else{
            localStorage.removeItem('access_token');
            localStorage.removeItem('user');
            return false; 
        }

    }else{
        localStorage.removeItem('user');
        return false;
    
    }
    
}

const validateToken = () =>{
    const expiration_token = localStorage.getItem('expiration_token');
    if(expiration_token){
      let expiration = parseFloat(expiration_token);
      let now = Math.floor(Date.now() / 1000);
      if (now > expiration) {
        return false;
      }else{

        return true;
      }
    }else{
      return false;
    }


}

export class AudioManager{
    private _isPlaying = false;
    audioObject: HTMLAudioElement;
    get isPlaying(){
        return this._isPlaying;
      }
    play(audioFile:string){
        // console.log(this.audioObject);
       
        this.audioObject.pause();
        this.audioObject.src = audioFile;
        this.audioObject.load();
        this.audioObject.play();
        this._isPlaying = true;
    }

    pause(){
        this.audioObject.pause();
        this._isPlaying = false;
    }

    constructor(){
        this.audioObject = document.createElement('audio');
        
    }
}

export  const audioService = new AudioManager();

