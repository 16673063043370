import {  IonButton, IonContent, IonPage} from '@ionic/react';
import { Link } from 'react-router-dom';
import MlmHeader from '../components/MlmHeader';
import { useEffect, useState } from 'react';
import { checkToken, getProfile } from '../services/api';
import Loader from '../components/Loader';

import Slider from "react-slick";


interface UserProfile {
  fullName: string;
  nickName: string;
  email: string;
  phone: string;
}

const Upgrade: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const [userId, setuserId] = useState('');
  
  const [profile, setProfile] = useState<UserProfile>();
  
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    centerMode: true,
    responsive: [
      {
        breakpoint: 768,
        settings: {
            slidesToShow: 3
        }
      },
      {
        breakpoint: 480,
        settings: {

            slidesToShow: 1
        }
      }
    ]
  };

  const fetchProfile = async () => {
        
  

    const userProfile:any = await getProfile();
    if(userProfile){

      setProfile(userProfile);
      setLoading(false);
    }
    console.log(userProfile);
  
    
  };
  useEffect(() => {
    console.log('useeffect')
    let tokenIsValid = checkToken();
    if(!tokenIsValid){
      console.log(tokenIsValid)
    
    }else{
      fetchProfile();
      const user = localStorage.getItem('user');
      if(user){
        const userObj= JSON.parse(user);
        console.log(userObj)
        setuserId(userObj.data.user_id);
      }
    }
  }, [])
  return (
    <IonPage>
    <MlmHeader themecolor='black'/>
  <IonContent fullscreen className='main_content share_screen ion-padding'>
  <Loader isloading={loading}/>
  {(profile && !loading)&&
            (
    <div className='container'>
    <h1>Matan’s Life Map: Create your own</h1>
    <p>A guided digital organizer for information your family will
need if something happens to you</p>
<p><strong><em>12 questions. 45 minutes. Peace of mind.</em></strong></p>
<p>Complete payment to unlock your Life Map</p>

    {/* <p><IonButton size="large" className="blue-button" shape="round" href={`https://buy.stripe.com/test_5kA6qwd7raJT5Gw4gg?prefilled_email=${profile?.email}&client_reference_id=${userId}`}  >Pay & Start</IonButton></p> */}
    <p><IonButton size="large" className="blue-button" shape="round" href={`https://buy.stripe.com/14kdT78h3crHatO7st?prefilled_email=${profile?.email}&client_reference_id=${userId}`}  >Pay & Start</IonButton></p>
 
    <h2 className="h1">Key Features</h2>
    <section className='features_wrapper'>

    <ul>
      <li>Simple and guided flow</li>
      <li>12 questions you can answer in under 45 minutes</li>
      <li>Easy to share</li>
      <li>Unlimited updates</li>
      <li>Your data is encrypted and we never have access to it</li>
      <li>One payment of $80</li>
      <li>...Your loved ones will be grateful you took the time</li>
    </ul>
    <Slider {...settings}>
              <picture className="screenshot_1">
								<source src="./assets/img/Menu.webp" type="image/webp"/>
								<source src="./assets/img/Menu.png" type="image/png"/>
								<img src="./assets/img/Menu.png" alt="screeshot #1" width="330" height="614"/>
							</picture>

              <picture className="screenshot_19">
								<source src="./assets/img/screenshot_19.webp" type="image/webp"/>
								<source src="./assets/img/screenshot_19.png" type="image/png"/>
								<img src="./assets/img/screenshot_19.png" alt="screeshot #1" width="330" height="614"/>
							</picture>

              <picture className="screenshot_18">
								<source src="./assets/img/screenshot_18.webp" type="image/webp"/>
								<source src="./assets/img/screenshot_18.png" type="image/png"/>
								<img src="./assets/img/screenshot_18.png" alt="screeshot #1" width="330" height="614"/>
							</picture>

              <picture className="screenshot_20">
								<source src="./assets/img/screenshot_20.webp" type="image/webp"/>
								<source src="./assets/img/screenshot_20.png" type="image/png"/>
								<img src="./assets/img/screenshot_20.png" alt="screeshot #1" width="330" height="614"/>
							</picture>

              <picture className="screenshot_29">
								<source src="./assets/img/screenshot_29.webp" type="image/webp"/>
								<source src="./assets/img/screenshot_29.png" type="image/png"/>
								<img src="./assets/img/screenshot_29.png" alt="screeshot #1" width="330" height="614"/>
							</picture>

              <picture className="screenshot_32">
								<source src="./assets/img/screenshot_32.webp" type="image/webp"/>
								<source src="./assets/img/screenshot_32.png" type="image/png"/>
								<img src="./assets/img/screenshot_32.png" alt="screeshot #1" width="330" height="614"/>
							</picture>

							<picture className="screenshot_31">
								<source src="./assets/img/screenshot_31.webp" type="image/webp"/>
								<source src="./assets/img/screenshot_31.png" type="image/png"/>
								<img src="./assets/img/screenshot_31.png" alt="screeshot #1" width="330" height="614"/>
							</picture>


        </Slider>
    </section>

    <video playsInline controls className='margin' poster='./assets/videos/video_overlay.webp'>
    <source src="./assets/videos/mlm_s.webm" type="video/webm"/>
    <source src="./assets/videos/mlm_s.mp4" type="video/mp4"/>
    </video>
    <p>Complete payment to unlock your Life Map</p>

    <IonButton size="large" className="blue-button" shape="round" href={`https://buy.stripe.com/14kdT78h3crHatO7st?prefilled_email=${profile?.email}&client_reference_id=${userId}`} >Pay & Start</IonButton>
    </div>
    )}
  </IonContent>
</IonPage>
  );
};

export default Upgrade;
