import {
  IonContent,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonMenu,
  IonMenuToggle,

} from '@ionic/react';


import React, { useState } from 'react';
import { messages, locale, i18nConfig } from '../interfaces/Messages';
import { useLocation ,Link} from 'react-router-dom';

import { logOutOutline, personCircleOutline,  shareSocialOutline, eyeOutline, createOutline } from 'ionicons/icons';
// import './Menu.css';

interface AppPage {
  url: string;
  iosIcon: string;
  mdIcon: string;
  src:string;
  title: string;
}

const appPages: AppPage[] = [
  // {
  //   title: 'Home',
  //   url: '/',
  //   iosIcon: heartOutline,
  //   mdIcon: heartSharp,
  // src:'./assets/img/icon/.svg',
  // },
  // {
  //   title: 'Onboard',
  //   url: '/onboard',
  //   iosIcon: heartOutline,
  //   mdIcon: heartSharp,
  // src:'./assets/img/icon/.svg',
  // },
  {
    title: 'My Life Map',
    url: '/questions',
    iosIcon: createOutline,
    mdIcon: createOutline,
    src:'./assets/img/icon/edit.svg',
  },
  
  {
    title: 'Finish & Share',
    url: '/review',
    iosIcon: shareSocialOutline,
    mdIcon: shareSocialOutline,
    src:'./assets/img/icon/eye.svg',
  },
  {
    title: 'Shared with me',
    url: '/shareWithMe',
    iosIcon: eyeOutline,
    mdIcon: eyeOutline,
    src:'./assets/img/icon/share.svg',
  },
  // {
  //   title: 'Shared',
  //   url: '/shared',
  //   iosIcon: shareSocialOutline,
  //   mdIcon: shareSocialOutline,
  // src:'./assets/img/icon/.svg',
  // },
  {
    title: 'Profile',
    url: '/profile',
    iosIcon: personCircleOutline,
    mdIcon: personCircleOutline,
    src:'./assets/img/icon/settings.svg',
  },

  {
    title: 'Add to Home Screen',
    url: '/homeScreen',
    iosIcon: personCircleOutline,
    mdIcon: personCircleOutline,
  src:'./assets/img/icon/plus-square-bold.svg',
  },
  {
    title: 'Logout',
    url: '/logout',
    iosIcon: logOutOutline,
    mdIcon: logOutOutline,
    src:'./assets/img/icon/logout.svg',
  }
  
];
//todo add reset password


const Menu: React.FC = () => {
  const [language, setLang] = useState(locale);
  const [tr, setLocale] = useState(i18nConfig.trans);
  const location = useLocation();


  

  return (
    <IonMenu contentId="main" type="overlay" swipe-gesture="false" >
      <IonContent >
        <div className='menu-wrapper'>
        <div className='menu-list'>
        <IonList lines="full" className="white_list " id="inbox-list">
          <IonListHeader><h2>Menu</h2></IonListHeader>
          
          {appPages.map((appPage, index) => {
            return (
              <IonMenuToggle key={index} autoHide={false}>
                <IonItem className={location.pathname === appPage.url ? 'selected' : ''}  routerLink={appPage.url} routerDirection="forward"  detail={false} >
                  <IonIcon slot="end" src={appPage.src}  />
                  <IonLabel>{appPage.title}</IonLabel>
                </IonItem>
              </IonMenuToggle>
            );
          })}
        </IonList>
        </div>
        <div className="footer-wrapper">
<p  className="footer-text">Matan’s Life Map is a Delaware,<br/>
USA registered company<br/>
© 2022-2023 Matan’s Life Map. All Rights Reserved.</p>
<p  className="footer-text ">
        <Link to={{ pathname: '/privacy-policy'}}>Privacy Policy</Link>,&nbsp; 
        <Link to={{ pathname: '/terms-of-service'}}>Terms of Service</Link>, &nbsp; 
        <Link to={{ pathname: '/cookie-policy'}}>Cookie Policy</Link>, &nbsp; 
        <a href="https://matanslifemap.com/contact-us/">Contact Us</a>
</p>
        </div>
        </div>
      </IonContent>
    </IonMenu>
  );
};

export default Menu;
