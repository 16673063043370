import React from "react";

export function getBrowserVisibilityProp() {

  if (typeof document.hidden !== "undefined") {
    // Opera 12.10 and Firefox 18 and later support
    return "visibilitychange";
  } 
  // else if (typeof document.msHidden !== "undefined") {
  //   return "msvisibilitychange";
  // } else if (typeof document.webkitHidden !== "undefined") {
  //   return "webkitvisibilitychange";
  // }
}

export function getBrowserDocumentHiddenProp() {
  // console.log(document.hidden);
  if (typeof document.hidden !== "undefined") {
    return "hidden";
  } 

  // else if (typeof document.msHidden !== "undefined") {
  //   return "msHidden";
  // } else if (typeof document.webkitHidden !== "undefined") {
  //   return "webkitHidden";
  // }
}

export function getIsDocumentHidden() {
  // return getBrowserDocumentHiddenProp();
  const f = getBrowserDocumentHiddenProp();
  if(f)
  return !document[f];
}

export function usePageVisibility() {
  const [isVisible, setIsVisible] = React.useState(getIsDocumentHidden());
  const onVisibilityChange = () => setIsVisible(getIsDocumentHidden());

  React.useEffect(() => {
    const visibilityChange:any = getBrowserVisibilityProp();

    document.addEventListener(visibilityChange, onVisibilityChange, false);

    return () => {
      document.removeEventListener(visibilityChange, onVisibilityChange);
    };
  });

  return isVisible;
}