
import React, {useEffect, useState } from 'react';


import { IonBadge,IonButton, IonText, IonContent, IonPage, IonCardContent } from '@ionic/react';

  import {checkToken} from '../services/api';

  import { useHistory } from "react-router-dom";

import AudioComp from '../components/audio';
import MlmHeader from '../components/MlmHeader';

const Page: React.FC = () => {

  const [showMessage, setMessage] = useState<boolean>(false);

  const history = useHistory();
  const scrollToBottom = ()=>{
    setMessage(true);
    setTimeout(() => {
      const el = document.getElementById("messagesEndRef");
      if (el!== null) {
        el.scrollIntoView();
      }
      
    }, 100);
  }
  useEffect(() => {
    let tokenIsValid = checkToken();
    if(!tokenIsValid){
      history.push('/');
      // window.location.href = "/";
    }
  }, [])
  return (
    <IonPage>
      <div className="video_wrapper home_video_wrapper">
            <video  autoPlay muted playsInline id="video_intro" >
            <source src="/assets/videos/SPLASH_ANIM_TRANSITION_V2.mp4" type="video/mp4"/>
            <img src="/assets/videos/Splash_Still.png" width="400" height="300" />
            </video>
           </div>
      <MlmHeader themecolor="white" transparent={true} position='relative' />

      <IonContent  className='ion-padding'>
      

        <div className="container ">
        <div className="onboard-wrapper">

      <h1>Welcome to Your Life Map</h1>
      <h2>BEFORE YOU START</h2>
      <p>Remember - this is in case of an emergency. You are doing the right thing, and your loved ones will appreciate it!</p>
      
      <p><strong>Kick-off:</strong><br/>It’s only 12 questions! And unlike the algebra test that kept you up in high school, you know all of these answers. </p>
      
      <p><strong>Less is more:</strong><br/>Don’t fret if you don’t know an answer – you can advance to the next question with any amount of detail. Just remember, anything you provide will make a big difference in the future. </p>
      
      <p><strong>Account numbers:</strong><br/>No need to share them on Life Map. Your loved ones just need to know, for example, where you have bank accounts and what type. They don’t need (and can’t use anyway) your account numbers.</p>
      
      <p><strong>Passwords:</strong><br/>No need to share them on Life Map. Your loved ones only need a few key passwords (Mobile, laptop, Gmail, facebook etc) to have access and ability to reset all other passwords. And we recommend physically writing them down and sharing the note’s location on Life Map. </p>
      
      <p><strong>Quick:</strong><br/>In approximately 45 minutes you can be ready to share the information with the important people in your life. Click “Finish & Share” at any point during this process and know that peace of mind is just around the corner.</p>
      
      <p><strong>Secure:</strong><br/>Life Map can’t read your data – only the trusted few you share it with will have access. If needed, you can restore the information at any time.</p>
      
      <p><strong>Tags:</strong><br/>When answering questions, click these oval shaped elements (for example <IonBadge className='chips-txt' slot="start" >investments</IonBadge> ) or for faster input and to add structure to your answer. Other than that it’s free text.</p>

      <IonButton expand="block" size="large" shape="round" onClick={
        () => {
          scrollToBottom();
         }}>Start</IonButton>
        </div>
        
        <div className={(showMessage) ? 'sound-card-overlay sound-card-overlay-active':'sound-card-overlay'} id="messagesEndRef" >
          <div className='sound-card'>

   
      <AudioComp themecolor="white" from="onboardP" audiofile="https://app.matanslifemap.com/assets/sounds/Q1-31122022.mp3"></AudioComp>

      <IonCardContent>
      <IonText>We created a soothing soundtrack to help you concentrate and relax. you can always turn it on and off on the top of your screen.</IonText>
      </IonCardContent>

      <IonButton fill="clear" routerLink="questions" routerDirection="none">No, thanks</IonButton>
    </div>
    </div>
    </div>
      </IonContent>
    </IonPage>
  );
};

export default Page;
