
import React, {useEffect} from 'react';


import {  IonContent, IonPage, } from '@ionic/react';



import Register from '../components/Register';

import MlmHeader from '../components/MlmHeader';
import { Link } from 'react-router-dom';

const Registration: React.FC = () => {

 
  useEffect(() => {
    

  }, [])
  return (
    <IonPage><div className="video_wrapper home_video_wrapper">
           <video  muted id='home_video' autoPlay loop playsInline>
            <source id="source_home" src='/assets/videos/SPLASH_ANIM_LOOP1_V2.mp4' type="video/mp4"/>
            <source id="source_webm_home" src='/assets/videos/SPLASH_ANIM_LOOP1_V2.webm' type="video/webm "/>
            </video>
        </div>
      <MlmHeader themecolor="white" menu={false} authNeeded={true} transparent={true} position="relative"/>

      <IonContent  className=''>
      

          <div className="register-wrapper ion-padding">
      <Register  />
      <p className="ion-text-center register_link"><Link to={{ pathname: '/'}}>Back to login</Link></p>
      </div>
      </IonContent>
    </IonPage>
  )
    
};

export default Registration;
