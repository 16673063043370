import { IonButton, IonBadge, IonContent, IonPage} from '@ionic/react';

import React, { useState, useEffect } from 'react';
import { sharedWithMeUsers,getUserSummary,checkToken,getPaidStatus, sendSession } from '../services/api';
import MlmHeader from '../components/MlmHeader';
import Login from '../components/Login';
import Loader from '../components/Loader';
import { useLocation } from 'react-router';




const Page: React.FC = () => {

  

  const [countShared, setshared] = useState(0);
  const [qlength, setLength] = useState();
  const [authNeeded, setAuth] = useState(true);
  const [loading, setLoading] = useState(false);
  const [paidUser, setpaid] = useState(false);
  const [ticking, setTicking] = useState(false);
  const [count, setCount] = useState(8);
  const [progress, setProgress] = useState(false);

  
  const search = useLocation().search;
  const session = new URLSearchParams(search).get('session');


  const fetchSharedWithMeUsers = async () => {
    const shared:any = await sharedWithMeUsers();
    const countShared = shared.length
    setshared(countShared);
  };

  const fetchUserSummary = async () => {
    setLoading(true);
    const ql:any = await getUserSummary();
   if (ql) {
     setLength(ql.length);
     setLoading(false);
   }
    // setLength(0);
  };
  const checkPaidStatus = async () => {
 
    const paidStatus:any = await getPaidStatus();
   console.log('paidStatus',paidStatus)
   if (paidStatus) {
    setpaid(paidStatus)
   }

  };
  const updatePaymentStatus = async () => {

    const paidSession:any = await sendSession(session);
    console.log('paidSession',paidSession)
    if (paidSession) {
      setpaid(paidSession.data.isPaid);
    }
   
  };
  const displayProgress = async () => {
    console.log('paidUser',paidUser);
    
    if(!paidUser){
     
      checkPaidStatus();
    }else{
      setTicking(false)
      setProgress(false)
    }
  };

  useEffect(() => {

    let tokenIsValid = checkToken();
    if(tokenIsValid){
    console.log('tokenIsValid',tokenIsValid)
    if(session){
      updatePaymentStatus();
    }else{
      checkPaidStatus();
    }
    setAuth(false);
      
      
    fetchUserSummary();
    fetchSharedWithMeUsers();

   


    }

    
  }, [authNeeded])
  // useEffect(() => {
  //   console.log('count',count);
  //   console.log('ticking',ticking);
  //   console.log('paidUser',paidUser);
  //   console.log('progress',progress);
  //  if(paidUser){
  //   setProgress(false)
  //  }
  //   if(session && !authNeeded && !paidUser){
  //     setProgress(true)
     
  //     if(count <= 95  ){
  //       const timer = setTimeout(() => {displayProgress() ;setCount(count+8)} , 5000)
  //       return () => clearTimeout(timer)
  //     }
  // }
  //  }, [count, ticking,session,authNeeded])

   
  return (
    <IonPage>
      <div className="video_wrapper home_video_wrapper">
           <video  muted id='home_video' autoPlay loop playsInline preload='auto'>
            <source id="source_webm_home" src='/assets/videos/SPLASH_ANIM_LOOP1_V2.webm' type="video/webm"/>
            <source id="source_home" src='/assets/videos/SPLASH_ANIM_LOOP1_V2.mp4' type="video/mp4"/>
            </video>
        </div>
     <MlmHeader themecolor="white" menu={authNeeded?false:true} authNeeded={authNeeded}  transparent={true} position="relative"/>

      <IonContent className="ion-padding">
        
        {(authNeeded ) && (
       <div className="register-wrapper ">
          <Login stateChanger={setAuth}/>
          </div>
          )} 
        
      
          {(!authNeeded && progress ) && (
            <>
            <div className="welcome-wrapper">
            <div className="progress-bar">
            <p>We're processing your payment and setting up your account. This will take about 1 minute, so please hold on.</p>
            <p>The hard part is done: You decided to create your Life Map, and your love ones are ruly going to appreciate it.</p>
          
         <div className="progress-bar-wrapper">
		
		<div className="meter">
			<span style={{width: `${count}%`}}></span>
		</div>

         </div>
          </div>
          </div>
            </>
       )}
       
           
      
      {(!authNeeded && !progress) && (
        <>
        <div className="welcome-wrapper">
        <div className="welcome_content">
        <h1>Welcome to Your Life Map</h1>
            <Loader isloading={loading}/>
         
                  
                  {(!paidUser && !loading)&&(
                    <IonButton expand="block" size="large" shape="round" routerLink="upgrade" routerDirection="forward">My Life Map</IonButton>
                  )}
                  {(paidUser && !loading)&&(
                    <>
                    
                  {(qlength  && !loading) ? <IonButton expand="block" size="large" shape="round" routerLink="questions" routerDirection="none">My Life Map</IonButton>: <IonButton expand="block" size="large" shape="round" routerLink="onboard" routerDirection="none">My Life Map</IonButton> }
                  </>
                  )}
                  {(!loading)&&(
                  <IonButton expand="block" size="large" shape="round"  className='home_share' routerLink="ShareWithMe"><IonBadge color="warning" slot="start">{countShared}</IonBadge> Shared with me</IonButton>
                  )}
         
         </div>
         </div>
      </>
      )}
        
      
      </IonContent>
    </IonPage>
  );
};

export default Page;
