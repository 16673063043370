
import { IonContent,IonPage,IonButton } from '@ionic/react';
import { useHistory } from "react-router-dom";
import MlmHeader from '../components/MlmHeader';

const Page: React.FC = () => {

    let history = useHistory();

  return (
    <IonPage>
    <MlmHeader themecolor='black' position="relative"/>

   <IonContent className='share_screen ion-padding'>
   <div className="container flex-container center-center">
    
    
     <img  src="../assets/img/error404.png"  alt="error 404"/>
     <IonButton onClick={() => history.goBack()} className='black_button' shape="round" size="large" >Back </IonButton>
  
     
    </div>


   </IonContent>
   

 </IonPage>
  );
};

export default Page;
