import messages_en from '../i18n/en.json';
// Get
// localStorage.getItem('dark-mode');
// Set

const internalLocale = localStorage.getItem('locale');
const translation = localStorage.getItem('tr');
// console.log(translation);
// const myObj: {[index: string]:any} = {}
export const messages:{[index: string]:any} = {
    
    'en': messages_en
    
};
export const locale = (internalLocale) ? internalLocale :'en'; 
export const str_translation = JSON.stringify(messages[locale]); 

// console.log(locale);
// console.log(messages[locale]);
export const tr =  (translation) ? translation : localStorage.setItem('tr',str_translation);
// console.log(tr);
export const i18nConfig = {
    defaultLocale: locale,
    messages,
    trans: str_translation
};

    