import {  IonContent, IonPage} from '@ionic/react';
import MlmHeader from '../components/MlmHeader';



const Contact: React.FC = () => {

  return (
    <IonPage>
    <MlmHeader themecolor='black'/>
  <IonContent fullscreen className='main_content share_screen ion-padding'>
    <div className='container'>
    <h1>Contact Us</h1>
    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus vitae tortor rutrum, blandit odio at, fringilla risus. Praesent suscipit arcu non diam laoreet, sit amet pretium elit mattis. Suspendisse tortor quam, semper at ligula in, faucibus blandit leo. Donec fermentum lacus ut nisi ultrices pharetra. Vestibulum vulputate nibh vitae felis sollicitudin consequat. Mauris dictum ligula ac sapien tempus, ut cursus erat mattis. Donec condimentum id tortor vitae varius.</p>
    <p>Nullam hendrerit, lacus ut egestas feugiat, nisi turpis dapibus nisl, non consectetur nulla felis ut dui. Duis molestie vitae ligula fringilla finibus. Pellentesque nisi risus, posuere quis feugiat id, accumsan quis justo. Aenean vel justo posuere, posuere metus ut, tristique leo. Duis condimentum turpis lacus, non dignissim ipsum luctus at. Phasellus imperdiet neque non efficitur ultricies. Praesent pretium arcu a ante eleifend, ut feugiat enim rutrum. Ut pharetra lacus vel lacus elementum condimentum. Etiam cursus velit libero, non dignissim nibh ultricies nec. In ornare finibus est eget aliquet. Vestibulum nec ante aliquet, feugiat tortor eu, rutrum risus. Nulla vitae tellus ligula. Etiam vestibulum semper magna, ornare tempor mi tempor sed. Phasellus felis odio, egestas ac tortor vitae, pulvinar placerat urna.</p>
    <p>Donec convallis nibh eget lacus malesuada viverra. Vivamus condimentum ac velit vel pretium. Phasellus vitae lacus urna. Sed interdum enim at ante euismod, id cursus tellus blandit. Proin in rhoncus ligula. Vestibulum ligula ante, semper quis sem in, sagittis mattis odio. Pellentesque molestie ultrices metus, eget euismod nulla tincidunt eu. Quisque lobortis mauris sit amet elit bibendum, non ultricies metus consectetur. Vivamus ante ipsum, sollicitudin in risus vitae, maximus fringilla velit. Nam luctus pretium erat nec bibendum. Suspendisse facilisis pharetra augue, id placerat purus hendrerit quis. Fusce dictum leo vitae luctus volutpat. Curabitur laoreet ac lacus id cursus. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos.</p>
    <p>Nunc facilisis libero eget sem volutpat accumsan. Duis in tristique ex. Suspendisse potenti. Pellentesque sed accumsan justo, quis laoreet orci. Nulla ac dolor ut dolor tincidunt mollis. Proin viverra eros eget ante finibus, ac hendrerit mauris molestie. Integer hendrerit suscipit ornare. Proin viverra tellus iaculis nisl sollicitudin malesuada. Vivamus aliquet varius malesuada. Mauris at ullamcorper turpis, ac iaculis lectus. Donec imperdiet leo ut nibh imperdiet, et porttitor lacus bibendum. Mauris tempor ligula ante, quis varius nisl molestie ut. Suspendisse sed mauris at urna porta consequat in id justo. Aliquam libero mauris, efficitur non sem id, bibendum euismod justo. Donec in condimentum mi, eu sodales felis. Phasellus ullamcorper maximus quam, ut ultrices ipsum egestas sed.</p>
    <p>Fusce ut posuere massa, ut aliquam lacus. Nullam metus nulla, dictum vitae magna et, imperdiet venenatis justo. Pellentesque nec pretium lorem. Nulla ultricies diam ut ipsum tincidunt, vel scelerisque dui semper. In hac habitasse platea dictumst. Praesent eu pretium purus. Donec eros ligula, ornare vitae vulputate in, ultricies nec sapien. Curabitur volutpat aliquet nisi, quis consequat risus pellentesque vel. Nulla accumsan, sem sit amet bibendum ultrices, sapien turpis eleifend dolor, sed eleifend sem nulla sit amet turpis. </p>
    </div>
  </IonContent>
</IonPage>
  );
};

export default Contact;
