import {  IonContent, IonPage} from '@ionic/react';
import MlmHeader from '../components/MlmHeader';


const Cookies: React.FC = () => {


  return (
    <IonPage>
        <MlmHeader themecolor='black'/>
      <IonContent fullscreen className='main_content share_screen ion-padding'>
        <div className='container'>
        <h1>Cookie Policy</h1>
        
		
				
   
        
        
        
        <p><strong>Introduction</strong>:</p>
        
        
        
        <p>Hello and welcome to Matan’s Life Map, Inc.’s Cookie Policy (the “Cookie Policy”)! In the spirit of openness and transparency we want to be clear around the information we collect when you access and use our Sites and Service.&nbsp;</p>
        
        
        
        <p>This page contains information on what Cookies are, the types of Cookies used on our Sites and Service, how to switch Cookies off in your browser, and some useful links for further reading on the subject. If you are unable to find the information you were looking for, or you have any further questions about the use of Cookies on our Sites and Service, please email contact@matanslifemap.com.</p>
        
        
        
        <p>Use of our Site and Service is governed by our online <a href="https://matanslifemap.com/terms-of-service/">Terms of Use</a> as supplemented by other agreements and policies including our Privacy Policy and this Cookie Policy. Any undefined capitalized term in this Cookie Policy shall have the meaning set forth in the Terms of Use or Privacy Policy as applicable. More information about our privacy practices can be found by visiting our <a href="https://matanslifemap.com/privacy-policy/">Privacy Policy</a>.&nbsp;</p>
        
        
        
        <p><strong>What are Cookies.</strong></p>
        
        
        
        <p>You can think of Cookies as providing a so-called memory for a website, so that it can recognize you when you come back and respond appropriately. Cookies are small pieces of information, often small text files, normally consisting of just letters and numbers, which we provide when our Users visit our Sites and use our Service (“Cookie(s)”). Software on your device (for example a web browser) can store Cookies and send them back to the Sites next time you visit.</p>
        
        
        
        <p><strong>Why we use Cookies</strong>.</p>
        
        
        
        <p>We may use Cookies or “local storage” to, among other things: allow you to use the Sites and Service without having to re-enter your username and password; enhance or personalize your user experience according to your individual interests and the device or browser used; monitor usage; manage the Site and Service; develop and improve the Site and Service; track your use of the Sites and Service; gather information about the your approximate geographic location; provide localized content; prevent fraud and/or abuse; estimate our audience size and usage pattern and perform other analytics; and administer targeted advertising and our advertising campaigns to you across different websites and devices. If you choose, you can set your browser to reject Cookies or you can manually delete individual Cookies or all of the Cookies on your computer by following your browser’s help file directions. However, if your browser is set to reject Cookies or you manually delete Cookies, certain functionality may be limited or restricted.</p>
        
        
        
        <p>We may also use web beacons and other applications or features to help us analyze user behavior and measure the effectiveness of the Service and our advertising. We may work with service providers that help us track, collect and analyze this information. We may combine the information we collect through Cookies and web beacons with other information we have collected from you or information from other sources.</p>
        
        
        
        <p><strong>Types of Cookies</strong>:</p>
        
        
        
        <p>There are a few types of Cookies that we use on our Sites and Service. Session Cookies are stored only while you are accessing the Sites and using the Service and expire at the end of your browser session. These Cookies allow our Sites to recognize and link your actions during a browsing session. Persistent Cookies are Cookies that are stored on your device in-between sessions and can allow us to remember your preferences or actions. Persistent Cookies allow us to make our Sites more user-friendly, effective and safe and to record your acceptance of our Cookie Policy detailed herein. Please note that the data collected through Cookies will not be kept for longer than is necessary to fulfil the purposes mentioned in this Cookie Policy.</p>
        
        
        
        <p>Additionally, it is important for you to understand who sets Cookies on your devices. First-party Cookies are Cookies that belong to us and are set by us on your laptop, computer, and other devices that you use to access the Sites and Service. Third-Party Cookies are Cookies that belong to everyone other than us. These Cookies are from another party who provides us a service and places Cookies on your devices when you use our Sites.</p>
        
        
        
        <p><strong>How we use Cookies</strong></p>
        
        
        
        <p>Generally, we use several different types of Cookies on our website and platform:&nbsp;&nbsp;</p>
        
        
        
        <ul>
        <li><strong>Strictly Necessary Cookies</strong> – those Cookies are essential to navigate around the Site and use its features. For example, they allow us to remember your Cookie preferences and to verify if something is not working properly, so we can fix it quickly.</li>
        
        
        
        <li><strong>Performance Cookies</strong>: This type of Cookie helps us to secure and better manage the performance of our Sites and Service, and remembers your preferences for features found on our Sites and Service, so you don’t have to re set them each time you visit.&nbsp;</li>
        
        
        
        <li><strong>Analytics Cookies</strong>: Every time you visit our Sites and Service, the analytics tools and Service we generate Cookies which can tell us (so long as they are allowed and not deleted) whether or not you have visited our Sites and Service in the past, and provide additional information regarding how visitors and users use our Sites and Service (such as how many visitors we have on a certain landing page, how often they visit, or where users tend to click on our Sites and Service). Your browser will tell us if you have these Cookies and, if you don’t but do allow new Cookies to be placed, we will typically generate and place new ones.&nbsp;&nbsp;</li>
        
        
        
        <li><strong>Registration Cookies</strong>: When you register and sign into our Sites or Service, we generate Cookies that let us know whether you are signed in or not, and maintain your login session.&nbsp;</li>
        </ul>
        
        
        
        <p>Our servers use these Cookies to work out which account on our Sites and Service you are signed into and if you are allowed access to a particular area or feature on such account.&nbsp;</p>
        
        
        
        <p>While you are signed into our Sites or Service, we combine information from your Registration Cookies with Analytics Cookies, which we could use to learn, for example, which pages you have visited.&nbsp;</p>
        
        
        
        <ul>
        <li><strong>Marketing &amp; Advertising Cookies</strong>: These Cookies allow us to know whether or not you’ve seen an ad or a type of ad online, how you interacted with such an ad, and how long it has been since you’ve seen it.&nbsp;</li>
        </ul>
        
        
        
        <p>We also use Cookies to help us with targeted advertising. We may use Cookies set by another organization, so we can more accurately target advertisements to you.</p>
        
        
        
        <p>We also set Cookies on certain other sites that we advertise on. If you receive one of those Cookies, we may use it to identify you as having visited that site and viewing our ad there, if you later visit our Sites and Service. We can then target our advertisements based on this information.&nbsp;</p>
        
        
        
        <ul>
        <li><strong>Third-Party Integration Cookies</strong>: On some pages of our Sites and Service, other organizations may also set their own Cookies. They do this to enable and improve the performance and interoperability of their applications, features or tools that are integrated with our Sites and Service, to track their performance, or to customize their Service for you.</li>
        </ul>
        
        
        
        <p><strong>How Do I change my Cookie settings?</strong></p>
        
        
        
        <p>You can change your Cookie preferences at any time by using the settings in the Service. You can then check or uncheck the available boxes describing the specific Cookies, then click ‘save.’ You may need to refresh your page for your settings to take effect.</p>
        
        
        
        <p>Alternatively, most web browsers allow some control of most Cookies through the browser settings. To find out more about Cookies, including how to see what Cookies have been set, visit <a href="http://www.aboutcookies.org" target="_blank" rel="noopener" aria-label="external link">www.aboutcookies.org</a> or <a href="http://www.allaboutcookies.org/" target="_blank" rel="noopener" aria-label="external link">www.allaboutcookies.org</a>.</p>
        
        
        
        <p>Find out how to manage Cookies on popular browsers:</p>
        
        
        
        <ul>
        <li><a href="https://support.google.com/accounts/answer/61416?co=GENIE.Platform%3DDesktop&amp;hl=en" target="_blank" rel="noopener" aria-label="external link">Google Chrome</a></li>
        
        
        
        <li><a href="https://privacy.microsoft.com/en-us/windows-10-microsoft-edge-and-privacy" target="_blank" rel="noopener" aria-label="external link">Microsoft Edge</a></li>
        
        
        
        <li><a href="https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences" target="_blank" rel="noopener" aria-label="external link">Mozilla Firefox</a></li>
        
        
        
        <li><a href="https://support.microsoft.com/en-gb/help/17442/windows-internet-explorer-delete-manage-cookies" target="_blank" rel="noopener" aria-label="external link">Microsoft Internet Explorer</a></li>
        
        
        
        <li><a href="https://www.opera.com/help/tutorials/security/privacy/" target="_blank" rel="noopener" aria-label="external link">Opera</a></li>
        
        
        
        <li><a href="https://support.apple.com/en-gb/safari" target="_blank" rel="noopener" aria-label="external link">Apple Safari</a></li>
        </ul>
        
        
        
        <p>To find information relating to other browsers, visit the browser developer’s website.</p>
        
        
        
        <p>To opt out of being tracked by Google Analytics across all websites, visit <a href="http://tools.google.com/dlpage/gaoptout" target="_blank" rel="noopener" aria-label="external link">http://tools.google.com/dlpage/gaoptout</a>.</p>
        
        
        
        <p><strong>Changes to the Cookie Policy</strong></p>
        
        
        
        <p>We reserve the right to change this Cookie Policy at any time, so please re-visit this page frequently to check for any changes. In case of any material change, we will make reasonable efforts to post a clear notice on the Site or we will send you an e-mail regarding such changes to the e-mail address that you may have provided us with. Such material changes will take effect seven (7) days after such notice was provided on our Site or sent to you via e-mail, whichever is the earlier. Otherwise, all other changes to this Cookie Policy are effective as of the stated “Last Updated” and your continued use of the Site on or after the Last Updated date will constitute acceptance of, and agreement to be bound by, those changes. In the event that this Cookie Policy should be amended to comply with any legal requirements, the amendments may take effect immediately, or as required by the law and without any prior notice.</p>
        
        
        
        <p><strong>Have any Questions?</strong></p>
        
        
        
        <p>If you have any questions (or comments) concerning this Cookie Policy, please send us an email to the following address: <a href="mailto:contact@matanslifemap.com">contact@matanslifemap.com</a> and we will make an effort to reply within a reasonable timeframe.</p>
        
        
        
        <p>Last Updated: April 30, 2023</p>
        
        </div>
      </IonContent>
    </IonPage>
  );
};

export default Cookies;
