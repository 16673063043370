import {
    IonContent,
    IonButton,
    IonItem,
    IonLabel,
    IonInput,
    IonSpinner,
    IonText,
    IonNote,
    IonCheckbox
  } from '@ionic/react';
  import React, { useState,useEffect } from 'react';
  import {  locale, i18nConfig } from '../interfaces/Messages';
  import { register } from '../services/api';
import Loader from '../components/Loader';
import {Link, useHistory,useLocation} from 'react-router-dom';
import { SharedContact } from '../interfaces/User';

import { getGeolocation,sendOtp } from '../services/api';

import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import { isValidPhoneNumber } from 'react-phone-number-input'
import validator from 'validator'


type Props = {
  contact?: SharedContact, 
}
type registrationData ={

    "email"?:string,
    "password"?:string,
    "key"?:string,
    "otpCode"?:string,
    "phone"?:string,
    "lastName"?:string,
    "firstName"?:string,

}
const Register: React.FC<Props> = ({ contact }): JSX.Element => {
  
    const [language, setLang] = useState(locale);
    const [tr] = useState(JSON.parse(i18nConfig.trans));
    const [loading, setLoading] = useState(false);

    const [loginResponse, setRes] = useState('');
    const [error, setError] = useState('');
    const [message, setMessage] = useState('');

    const [phoneValue, setPhone] = useState<string>('');
    const [emailValue, setEmailValue] = useState<string>('');
    const [emailIsValid, setEmailIsValid] = useState<boolean>();
    const [passwordMatch, setpasswordMatch] = useState<boolean>(true);
    const [emailMatch, setpemailMatch] = useState<boolean>(true);
    const [strongPassword, setpStrongPassword] = useState<boolean>(true);
    const [logged, setLogged] = useState<boolean>(false);
    const [notValidPhone, setNotValidPhone] = useState(true); 
    const [userCountry, setuserCountry] = useState();
    const search = useLocation().search;
    const key = new URLSearchParams(search).get('key')!;

    const history = useHistory();

    const validateEmail = (email: string) => {
      return validator.isEmail(email);
      
      // return email.match(
      //   /^(?=.{1,254}$)(?=.{1,64}@)[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/

      // );
    };

    const validateStrongPassword = (password: string) => {
      if (validator.isStrongPassword(password, {
        minLength: 6, minLowercase: 1,
        minUppercase: 1, minNumbers: 1,minSymbols: 0
      })) {
        setpStrongPassword(true);
      } else {
        setpStrongPassword(false)
      }
    }
   
    const handlePhoneChange = (phone:any)=>{
      // console.log(phone);
      if(phone ){
          setPhone(phone);
            let isValidPhone = isValidPhoneNumber(phone);
            console.log('isValidPhone',isValidPhone);
            setNotValidPhone(isValidPhone);
      }
  }
    const validatePassword = (password: string) => {
      var pass = (document.getElementById('password') as HTMLInputElement).value;
      
      if(pass !== password){
        setpasswordMatch(false)
      }else{
        setpasswordMatch(true)
      }
    };
    const matchEmail = (email: string) => {
      var mail = (document.getElementById('email') as HTMLInputElement).value;
      
      if(mail !== email){
        setpemailMatch(false)
      }else{
        setpemailMatch(true)
      }
    };
  
    const validateEmailEvent = (ev: Event) => {
      const value = (ev.target as HTMLInputElement).value;
      setEmailIsValid(undefined);
      if (value === '') return;
      setEmailValue(value);
      validateEmail(value) ? setEmailIsValid(true) : setEmailIsValid(false);

    };
  
    const setExpirationToken =(expiration:string)=>{
      localStorage.removeItem('expiration_token');
      let now = Math.floor(Date.now() / 1000);
      let endToken = now + parseFloat(expiration);
      console.log(now,endToken);
      localStorage.setItem('expiration_token', endToken.toString());
    }


    
    const handleSubmit = (data:any) => {
      setLoading(true);
      data.preventDefault();

      const rData:registrationData ={};
      if(data.target['email']){
        rData.email= data.target['email'].value;
      }
      if(data.target['password']){
        rData.password= data.target['password'].value;
      }
      if(data.target['userkey']){
        rData.key= data.target['userkey'].value;
      }
      if(data.target['otpCode']){
        rData.otpCode= data.target['otpCode'].value;
      }
      if(phoneValue){
        rData.phone= phoneValue;
      }
      rData.firstName= data.target['firstName'].value;
      rData.lastName= data.target['lastName'].value;
      // console.log(data)
      // console.log(rData)
      register(rData)
        .then((response) => {
         
          if(response.data.user_id){
           //to login access token
           console.log(response.data.access_token);
           const res = JSON.stringify(response);
          //  console.log('register_res',res);
           localStorage.removeItem('user');
           localStorage.setItem('user', res);
           localStorage.setItem('p', response.data.paid);
          
           localStorage.removeItem('access_token');
           localStorage.setItem('access_token', response.data.access_token);
           setExpirationToken(response.data.expires_in);
           window.location.href = "/";
          }
          if(response.data.error){
            if(response.data.error.message === 'The password must be a string with at least 6 characters.'){
              setError('A password needs to have at least 6 characters, including a capital letter and a number.');
            }else{
              setError(response.data.error.message);
            }
            
          }
        }).finally(() => {
          if(contact){
            history.push('shareWithMe?firstName='+contact?.originalUserName+'&id='+contact?.originalUserId);
           }
           
          setLoading(false);
        });
    }

    const resendOtp = async () =>{
      
      const resendOtp:any = await sendOtp(key);
      console.log('resendOtp',resendOtp)
      setMessage(resendOtp.data.message);
    }

    useEffect(() =>{
      setEmailIsValid(true);
    }, [contact]);
    useEffect(() => {
      const user = localStorage.getItem('user');
      if(user){
        setLogged(true);
      }
      getGeolocation()
      .then((response) => {
       
        if (response && response.YourFuckingCountry) {
          // console.log(response);
          setuserCountry(response.YourFuckingCountryCode); 
        }
      })
    }, [logged]);
  
    return (
      
        <div>

          {(!logged) &&(
            <>
             <h1 className='ion-text-center'>Register</h1>
          <form className="smallForm" onSubmit={handleSubmit}>
          <IonItem className="login_item">
              <IonLabel>First Name</IonLabel>
              <IonInput type="text" required  id="firstName" name="firstName" clearOnEdit={false} />
              
              
            </IonItem>
            <IonItem className="login_item">
              <IonLabel>Last Name</IonLabel>
              <IonInput type="text" required  id="lastName" name="lastName" clearOnEdit={false} />
              
              
            </IonItem>
            <IonItem className="login_item">
                <IonLabel>{tr.email}</IonLabel>
          
                <IonInput type="email" required name='email' id='email'
             disabled={contact?.sharedType ==="email" }
                  onIonInput={(event) => validateEmailEvent(event)}
                  value={(contact?.sharedType ==="email"?contact?.value:emailValue)}
                  autofocus
                
                ></IonInput>
                {(!emailIsValid) && (<IonNote slot="helper" color="warning">Invalid email</IonNote>)}
                
            </IonItem>
            <IonItem className="login_item">
              <IonLabel>Confirm Email</IonLabel>
              <IonInput type="email" required id="matchEmail" name="matchEmail" clearOnEdit={false} onIonChange={(event:any) => matchEmail(event.target.value)} />
              {(!emailMatch) && (<IonNote slot="helper" color="warning">Email not match</IonNote>)}
              
            </IonItem>
            <IonItem className="login_item">
              <IonLabel>{tr.password}</IonLabel>
              <IonInput type="text" required  id="password" name="password" clearOnEdit={false} onIonChange={(event:any) => validateStrongPassword(event.target.value)} />
              {(!strongPassword) && (<IonNote slot="helper" color="warning">A password needs to have at least 6 characters, including a capital letter and a number. </IonNote>)}
              
              
            </IonItem>
            
            {(contact) &&(
              <div>
              <p>Please enter the one time password you received in your {contact?.sharedType}. 
              <span onClick={resendOtp} className="resendOtp "> Send again</span></p>
              {(message) &&(
              <p >{message}</p>
            )}
              
            <IonItem className="login_item">
                <IonLabel>One time password</IonLabel>
                <IonInput type="text" required name='otpCode'
                  placeholder='otpCode'
                  ></IonInput>
                <input type="hidden" name="userkey" id="userkey" value={key} />
            </IonItem></div>
                  )}
              <IonItem className="profile_item register_phone ">
        
          <PhoneInput
                    name="phoneField"
                    id="phoneField"
                    placeholder="Enter phone number"
                    defaultCountry={userCountry}
                    value={(contact?.sharedType ==="phone"?contact?.value:phoneValue)}
                    onChange={(phone) => handlePhoneChange(phone)}
                    
                    />
                 

        </IonItem>  
        <p>
          <input type="checkbox" name="agreement" id="agreement" required />
        
        <IonLabel> I agree to the <Link to={{ pathname: '/terms-of-service'}}>Term of Service</Link> and <Link to={{ pathname: '/privacy-policy'}}>Privacy Policy</Link>, including  <Link to={{ pathname: '/cookie-policy'}}>Cookie Use</Link>.</IonLabel>
        </p>
          
        {(!notValidPhone ) &&  (
            <IonLabel color="danger">  Please enter a valid phone number</IonLabel>
            
            )}  
              
            <IonButton expand="block"  size="large" shape="round"  className='yellow_button' id="RegisterBtn" type="submit" disabled={!emailMatch || !emailIsValid }>Register</IonButton>
            {(error) &&(
              <p className='errormessage'>{error}</p>
            )}
      </form>
        
      </>
      )}
      {(logged) &&(
        <p>You are already logged in </p>
      )}
      <Loader isloading={loading}/>

        </div>
    
    );
  };
  
  export default Register;
  