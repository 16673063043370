import  { FC,useState, useEffect }from 'react';

import { IonIcon,IonToggle,IonLabel,IonInput,IonButton} from '@ionic/react';

import { getGeolocation } from '../services/api';

import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import { isValidPhoneNumber } from 'react-phone-number-input'

interface contact{
    id?: string, 
    name?: string, 
    val?: string, 
    type?: string, 
}
type Props = {
    item: contact, 
    index:Number,
    disable?:boolean,
    onClick:any
  }
const ShareTo: FC<Props> = ({item,index,onClick,disable}): JSX.Element => {
   
    const [userCountry, setuserCountry] = useState();
    const [shareType, setShareType] = useState('email');
    const [notValidEmail, setNotValidEmail] = useState(true);
    const [notValidPhone, setNotValidPhone] = useState(true);


    function isValidEmail(email:string) {
        return /\S+@\S+\.\S+/.test(email);
      }
    
    const handlePhoneChange = (phone:any)=>{
        if(phone){
            item.val=phone;
            //   setPhone(phone);
              let isValidPhone = isValidPhoneNumber(phone);
              console.log('isValidPhone',isValidPhone);
              setNotValidPhone(isValidPhone);
        }
    }
    
    const handleNameChange =(event:any)=>{
        item.name=event.target.value;
    }

    const handleEmailChange = (event:any) =>{
        // console.log(event.target.value);
        item.val = event.target.value;
        setNotValidEmail(isValidEmail(event.target.value));
    }

    const handleTypeChange = () =>{
      // console.log('changed');
      console.log(shareType);
      if(shareType === 'phone'){
        setShareType('email');
        item.type='email';
      }else{
        setShareType('phone');
        item.type='phone';
      }
    }

    // const removeFields = (index:any) => {
    //    console.log(index)
    //    let itemUI = document.getElementById('item_'+index);
    //    if (itemUI) {
    //     itemUI.remove();
    //    }
    // }
      
    useEffect(() => {
      console.log(item);
        if(item.type){
            // setShareType(item.type);
        }else{
            item.type=shareType;
        }
        console.log(shareType);
        getGeolocation()
        .then((response) => {
         
          if (response && response.YourFuckingCountry) {
            // console.log(response);
            setuserCountry(response.YourFuckingCountryCode); 
          }
        })
    }, [])
    return(
        <div className='share_item_wrapper' id={(item.id)?`item_${item.id}`:`item_${index}`}  >
            
            




            <div className="share_item">
           
              <IonInput name="name"
                  placeholder='Name'
                  required
                  onIonChange={(event:any) => handleNameChange(event)}
                  value={item.name}
                  disabled={disable}></IonInput>
              
              

                {(shareType === 'phone')&&(
             
                  <PhoneInput
                    name="phoneField"
                    placeholder="Enter phone number"
                    defaultCountry={userCountry}
                    value={item.val}
                    required
                    onChange={(phone) => handlePhoneChange(phone)}
                    disabled={disable}
                    // className={(disable)?'PhoneInput--disabled':'sss'}
                    />

             
                )}
                {(shareType === 'email')&&(
              
                  <IonInput 
                  name="emailField"
                  placeholder='Email'
                  disabled={disable}
                  required
                  onIonChange={(event:any) => handleEmailChange(event)}
                  value={item.val}></IonInput> 
               
                )}
              
            
              
              
              {/* <IonButton onClick={() => removeFields((item.id)? item.id:index)}><IonIcon icon={trashOutline}  /></IonButton> */}
              <IonButton onClick={onClick} className="removeItem"><IonIcon icon="./assets/img/icon/trash.svg"  /></IonButton>

              
            </div>
            {(shareType === 'email' && !notValidEmail ) && (
            <IonLabel color="danger">Please enter a valid Email</IonLabel>
            )}
            {(shareType === 'phone' && !notValidPhone ) &&  (
            <IonLabel color="danger">  Please enter a valid phone number</IonLabel>
            
            )}
          </div>
    )
};


export default ShareTo;

