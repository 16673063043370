import React, { FC}from 'react';

import { IonLabel,IonText} from '@ionic/react';



type Props = {
  text:string,
  cls?:string
}

const WhiteWrapper: FC<Props> = ({text,cls}): JSX.Element => {


    return(

        <div className={`white_wrapper ${cls}`}>
            <div className="white_container">
                <IonLabel className='label ddd'>{text}</IonLabel>
                
                <img  src="../assets/img/nothing.png" className="nothing" alt="butterfly"/>
            </div>
        </div>
    )
};


export default WhiteWrapper;

