import React, { FC,useState, useEffect }from 'react';
// import { useParams } from 'react-router';
import { useLocation } from 'react-router-dom';
import { IonButton,IonIcon,IonText} from '@ionic/react';
import { volumeHighOutline, volumeMuteOutline } from 'ionicons/icons';

import { usePageVisibility } from "../utils/visibility";
import {audioService} from "../services/api"


type Props = {
  themecolor?: string, 
  audiofile?: any,
  from?:string
}

const AudioComp: FC<Props> = ({ themecolor, audiofile ,from}): JSX.Element => {
  const isVisible = usePageVisibility();

  const [audio, setAudio] = useState(audiofile)
  const [isPlaying, setIsPlaying] = useState<Boolean>(audioService.isPlaying)
  const location = useLocation();


    const toggleAudio = () =>{
      
        if (audioService.audioObject.paused) {
          audioService.play(audio)
        } else {
          audioService.pause()
        }
        setIsPlaying(audioService.isPlaying)
        console.log('isPlaying2',audioService.isPlaying)
       
    }


    const onTrackChange = (audiofile:any) => {
      console.log('isPlaying1',audioService.isPlaying)
          setAudio(audiofile);
  
          
          if (audioService.isPlaying){
          audioService.play(audiofile)
         
        }
        setIsPlaying(audioService.isPlaying)
      
  }

  const onVisibilityChange = (isVisible?:boolean) => {
    
    
    if (isVisible && audioService.isPlaying) {
      audioService.play(audio)
      
    } else if (!isVisible && audioService.isPlaying ){
      audioService.pause()
    }
  };
  useEffect(() => {
    onVisibilityChange(isVisible);
  },[isVisible])

  useEffect(() => {
    
    onTrackChange(audiofile)
  },[audiofile])



    return(

        <div slot={(location.pathname === "/onboard")? "" :"start"}>
   
      
   
        {(location.pathname === "/onboard")&&(
        

          <IonButton  shape="round" slot="start" size="large" className={`sound-button white-button header_${themecolor}`} onClick={toggleAudio } routerLink="questions" routerDirection="none"> <IonText slot="start" className='audio-label'>Turn the sound on</IonText><IonIcon  icon={volumeHighOutline}></IonIcon>
              </IonButton>

          
        )}
        
{(location.pathname !== "/onboard")&&(



<IonButton  shape="round" slot="start" size="large" className= {`share_button header-sound-button header_${themecolor}`  } onClick={toggleAudio} > 
      {(!isPlaying) ? <IonIcon  icon={volumeMuteOutline}></IonIcon>: <IonIcon  icon={volumeHighOutline}></IonIcon> }
          </IonButton>

          )}
         </div> 
      
    )
};


export default AudioComp;

