  import  { useEffect,useState } from 'react';
  import { IonContent,  IonPage, IonButton,useIonViewWillEnter } from '@ionic/react';
  import { checkToken,getUserSummary } from '../services/api';

  import MlmHeader from '../components/MlmHeader';
  import Loader from '../components/Loader';
  import WhiteWrapper from '../components/WhiteWrapper';

  import { useHistory } from "react-router-dom";
  interface Answer{
    id: string;
    title: string;
    answer:string;
  }
  const Page: React.FC = () => {

      const data:any = localStorage.getItem('form');
      const [userAnswers, setUserAnswers] = useState(null as Answer[] | null);
      const [loading, setLoading] = useState(true);

      // const formData:String[] = JSON.parse(data);
      
      var acc = document.getElementsByClassName("accordion");
      var i;

      const fetchAnswer =async()=>{
        setLoading(true);
        let answers:any = await getUserSummary().then((response) => {
          console.log(response)
        if(response.length > 0){
          setUserAnswers(response);
      
        }
    
      }).finally(() => {
          setLoading(false);
      });
        // let answers:any = [];
      //   if(answers) {
        
        
      //   if(answers.length > 0){
      //     setUserAnswers(answers);
      //     setLoading(false);
      //   }else{
      //     setLoading(false);
      //   }
      // }
      }

      const openAccordion = (e:any) =>{
          console.log(e);
          var el = e.target;
          e.target.classList.toggle("active");
          var panel = el.nextElementSibling;
          if (panel.style.maxHeight) {
              panel.style.maxHeight = null;
          } else {
              panel.style.maxHeight = panel.scrollHeight + "px";
          }
      }


      let history = useHistory();
    useIonViewWillEnter(() => {
      let tokenIsValid = checkToken();
      if(!tokenIsValid){
        console.log(tokenIsValid);
        history.push('/');
      }else{
        fetchAnswer();
      }
    });

    return (
      <IonPage>
        <MlmHeader themecolor='black' position='relative'/>

        <IonContent fullscreen className='main_content share_screen'>
        <div className="container flex-container">

       
        
        {!userAnswers && !loading && (
          <><h1 className='share_title'> 
          Summary</h1>
          <WhiteWrapper text={`Nothing to show yet.\nAnswer some questions, and they will appear here.`} />
          
          <div className="bottom-buttons review-bottom-buttons buttons_true">
          <IonButton onClick={() => history.goBack()} className='black_button' shape="round" size="large" >Back </IonButton>
        
          <IonButton slot="end" shape="round" size="large" className=' white-button' routerLink="questions">   Create your own </IonButton>        
        
          </div>
          
          </>
          
          )}
          <Loader isloading={loading}/>
          {userAnswers && !loading && (
            
       <>
          <h1 className='share_title'>Summary</h1>
          <p className='padding'>Click the "share" button below if everything looks right</p>
              <div className="accordion_wrapper"> 
        <div>
          
              {userAnswers && userAnswers.map( (q:any,key:any)=>
        (
            <div className="cl-accordion" key={key}>
              <button className="accordion" onClick={openAccordion}>{key+1} - {q.title}</button>
              <div className="panel">
                {(q.answer && 
                <><hr/>{q.answer}</>
                )}
                </div>
          </div>
              
              )
              )}
              </div>
              </div>
              <div className="bottom-buttons review-bottom-buttons buttons_true">
          <IonButton onClick={() => history.goBack()} className='white-button' shape="round" size="large" >Back </IonButton>
          <IonButton shape="round" size="large" fill="clear" type="submit" className=' black_button' onClick={() => history.push('/share')} >
          Share
          </IonButton>
        
          </div>
         </>
        )}
        
        </div>
 
        </IonContent>
      </IonPage>
      
    );
  };

  export default Page;
